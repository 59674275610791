"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "buildTurnover", {
    enumerable: true,
    get: function() {
        return buildTurnover;
    }
});
const _datefns = require("date-fns");
const _timezone = require("./timezone");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const buildTurnover = (turnover)=>{
    const { airbnbCheckOutTime, nextReservation, isSameDayTurnover, timeZone, endDate } = turnover;
    const date = (0, _datefns.startOfDay)((0, _timezone.fromLocalToZoned)(endDate, timeZone)).toISOString();
    let adults;
    let children;
    let infants;
    let pets;
    let { firstName } = turnover;
    let startDate;
    let airbnbCheckInTime;
    let nextAirbnbNights;
    if (nextReservation) {
        var _nextReservation_guests, _nextReservation_guests1, _nextReservation_guests2, _nextReservation_guests3;
        firstName = nextReservation.airbnbFirstName;
        adults = (_nextReservation_guests = nextReservation.guests) === null || _nextReservation_guests === void 0 ? void 0 : _nextReservation_guests.adults;
        children = (_nextReservation_guests1 = nextReservation.guests) === null || _nextReservation_guests1 === void 0 ? void 0 : _nextReservation_guests1.children;
        infants = (_nextReservation_guests2 = nextReservation.guests) === null || _nextReservation_guests2 === void 0 ? void 0 : _nextReservation_guests2.infants;
        pets = (_nextReservation_guests3 = nextReservation.guests) === null || _nextReservation_guests3 === void 0 ? void 0 : _nextReservation_guests3.pets;
        startDate = nextReservation.startDate;
        airbnbCheckInTime = nextReservation.airbnbCheckInTime;
        nextAirbnbNights = nextReservation.airbnbNights;
    }
    let checkOutTime;
    let checkOutTimeAmPM;
    const hasCheckOut = airbnbCheckOutTime || airbnbCheckOutTime === 0;
    if (hasCheckOut) {
        checkOutTime = airbnbCheckOutTime.toString();
        checkOutTimeAmPM = "am";
        if (Number(checkOutTime) >= 12) {
            checkOutTimeAmPM = "pm";
            if (checkOutTime !== "12") {
                checkOutTime = (Number(checkOutTime) - 12).toString();
            }
        }
    }
    let checkInTime;
    const hasCheckIn = airbnbCheckInTime || airbnbCheckInTime === 0;
    if (hasCheckIn) {
        checkInTime = airbnbCheckInTime === null || airbnbCheckInTime === void 0 ? void 0 : airbnbCheckInTime.toString();
        if (checkInTime && Number(checkInTime) >= 12) {
            if (checkInTime !== "12") {
                checkInTime = (Number(checkInTime) - 12).toString();
            }
            if (checkOutTimeAmPM && checkOutTimeAmPM !== "pm") {
                checkOutTime += checkOutTimeAmPM;
            }
            checkInTime += "pm";
        } else {
            if (checkOutTime && checkOutTimeAmPM !== "am") {
                checkOutTime += checkOutTimeAmPM;
            }
            checkInTime += "am";
        }
    }
    let time = "-";
    if (hasCheckIn && !hasCheckOut) {
        time = `before ${checkInTime}`;
    } else if (!hasCheckIn && hasCheckOut) {
        time = `after ${checkOutTime}`;
    } else if (hasCheckIn && hasCheckOut) {
        time = `${checkOutTime} - ${checkInTime}`;
    }
    let nextCheckInTop;
    let nextCheckInBottom = "-";
    if (isSameDayTurnover) {
        nextCheckInTop = "Same Day";
        if ((0, _datefns.isSameDay)(new Date(date), new Date(Date.now()))) {
            nextCheckInTop = "Today";
        }
    }
    if (startDate && (0, _datefns.isValid)(new Date(startDate))) {
        const timezone = turnover.timeZone;
        nextCheckInBottom = (0, _datefns.format)((0, _timezone.fromLocalToZoned)(startDate || "", timezone), "MMM do");
    }
    return _object_spread_props(_object_spread({}, turnover), {
        nextCheckInTop,
        nextCheckInBottom,
        time,
        firstName,
        adults,
        children,
        infants,
        pets,
        date,
        nextAirbnbNights
    });
};
