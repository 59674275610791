"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    buildErrorsMessaging: function() {
        return buildErrorsMessaging;
    },
    buildErrorsPricing: function() {
        return buildErrorsPricing;
    },
    buildGlobalErrors: function() {
        return buildGlobalErrors;
    }
});
const _constant = require("../../../core/src/constant");
const _account = require("./account");
const _parseFailuresIntoArray = require("./parseFailuresIntoArray");
const buildErrorsPricing = (pricingRules, listing, pricingRule)=>{
    const errors = buildErrorsListing(listing);
    const pricingRuleCount = pricingRules.length;
    if (pricingRule) {
        const pricingRulesEnabledCount = pricingRules.filter((pricingRule)=>{
            return pricingRule.paused === false;
        }).length;
        errors.noListingPricingRules = pricingRuleCount === 0;
        errors.noListingEnabledPricingRules = pricingRulesEnabledCount === 0;
    }
    return errors;
};
const buildErrorsMessaging = (messageRules, listing)=>{
    const errors = buildErrorsListing(listing);
    errors.noListingMessageRules = messageRules.length === 0;
    return errors;
};
function buildErrorsListing(listing) {
    const errors = {};
    if (listing) {
        errors.noListingMinimumPrice = !listing.minPrice && listing.priceSource !== "Beyond Pricing";
        errors.noListingBasePrice = !listing.basePrice && listing.priceSource === "Amount";
        errors.listingPricingPaused = !listing.pricingEnabled;
        errors.listingPricesNotDownloaded = !listing.pricesDownloadedLast && !listing.pricesUpdatedLast;
        errors.listingDisabled = !listing.listingEnabled;
    }
    return errors;
}
function buildGlobalErrors(user, accounts, listings, visibleListings) {
    const errors = {};
    // User
    if (user.subscriptionStatus !== "active" && user.subscriptionStatus !== "trialing") {
        errors.userNotActive = true;
        errors.hasErrors = true;
    }
    const { badLockAccounts, badAccounts, connectedAccounts, badProviderAccounts } = accounts.reduce((result, account)=>{
        if ((0, _account.isDisconnectedAccount)(account)) {
            result.badAccounts.push(account);
        } else {
            result.connectedAccounts.push(account);
        }
        if (_constant.providerChannels.includes(account.type) && (0, _account.isDisconnectedAccount)(account)) {
            result.badProviderAccounts.push(account);
        }
        if (_constant.integrations.includes(account.type) && (0, _account.isDisconnectedAccount)(account)) {
            result.badLockAccounts.push(account);
        }
        return result;
    }, {
        badAccounts: [],
        connectedAccounts: [],
        badLockAccounts: [],
        badProviderAccounts: []
    });
    if (!user.originUserID) {
        if (connectedAccounts.length === 0 && badAccounts.length === 0) {
            errors.noAccounts = true;
            errors.hasErrors = true;
        } else {
            // Only show these errors if accounts already exist
            if (connectedAccounts.length === 0) {
                errors.noActiveAccounts = true;
                errors.hasErrors = true; // Don't want to show Timeline if there are no active accounts
            }
            if (badAccounts.length !== 0) {
                errors.nonActiveAccounts = true;
            // errors.hasErrors = true; // Still want to show the timeline if there are bad accounts and no good accounts
            }
            if (badProviderAccounts.length) {
                errors.hasDisconnectedProviderAccounts = true;
            }
            if (badLockAccounts.length) {
                errors.hasDisconnectedLockAccount = true;
            }
        }
    }
    if (listings.length === 0) {
        errors.noListings = true;
        errors.hasErrors = true;
    }
    const hasEnabledListings = listings.some((listing)=>{
        return listing.listingEnabled;
    });
    // either all listings are hidden or no listings are enabled
    if (listings.length > 0 && (!hasEnabledListings || visibleListings.length === 0)) {
        errors.noActiveListings = true;
        errors.hasErrors = true;
    } else if (listings.length > 0) {
        errors.listingsErrors = listings.reduce((acc, listing)=>{
            const errors = (0, _parseFailuresIntoArray.parseFailuresIntoArray)(listing.failures);
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                acc.push([
                    listing,
                    errors
                ]);
            }
            return acc;
        }, []);
    }
    if (connectedAccounts.length > 0) {
        errors.accountsErrors = connectedAccounts.reduce((acc, account)=>{
            const errors = (0, _parseFailuresIntoArray.parseFailuresIntoArray)(account.failures);
            if (errors === null || errors === void 0 ? void 0 : errors.length) {
                acc.push([
                    account,
                    errors
                ]);
            }
            return acc;
        }, []);
    }
    // all listings are hidden
    if (listings.length > 0 && visibleListings.length === 0) {
        errors.noVisibleListings = true;
        errors.hasErrors = true;
    }
    return errors;
}
