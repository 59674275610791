"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useMouseTracker", {
    enumerable: true,
    get: function() {
        return useMouseTracker;
    }
});
const _useEventListener = require("../../../../../frontend/src/react/hooks/useEventListener");
const _react = require("react");
function useMouseTracker() {
    const ref = (0, _react.useRef)(null);
    const [relativeX, setRelativeX] = (0, _react.useState)();
    const isMouseEnter = (0, _react.useRef)(false);
    const handleMouseEnter = (0, _react.useCallback)((e)=>{
        if (!ref.current) {
            return;
        }
        isMouseEnter.current = true;
        const rect = ref.current.getBoundingClientRect();
        const relativeX = e.clientX - rect.left;
        setRelativeX(relativeX);
    }, []);
    const handleMouseMove = (0, _react.useCallback)((e)=>{
        if (!ref.current) {
            return;
        }
        const rect = ref.current.getBoundingClientRect();
        const relativeX = e.clientX - rect.left;
        const relativeY = e.clientY - rect.top;
        if (relativeX < rect.width && relativeX > 0 && relativeY < rect.height && relativeY > 0) {
            setRelativeX(relativeX);
        } else {
            setRelativeX(undefined);
        }
    }, []);
    (0, _useEventListener.useEventListener)("mouseenter", handleMouseEnter, ref);
    (0, _useEventListener.useEventListener)("mousemove", handleMouseMove, ref);
    return {
        ref,
        relativeX
    };
}
