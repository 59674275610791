"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useChannels", {
    enumerable: true,
    get: function() {
        return useChannels;
    }
});
const _constant = require("../../../../../core/src/constant");
const _react = require("react");
const _channel = require("../../../models/channel");
function useChannels(version, exclude) {
    // don't necessarily re-calculate as soon as this value change for now
    // this goal is to allow to pass `exclude` without memo
    const excludeRef = (0, _react.useRef)(exclude);
    excludeRef.current = exclude;
    const channels = (0, _react.useMemo)(()=>{
        if (excludeRef.current.length) {
            return _constant.pricingChannels.filter((elem)=>excludeRef.current.some((channel)=>elem !== channel)).map((elem)=>{
                return (0, _channel.buildChannel)(elem);
            });
        }
        return _constant.pricingChannels.map((elem)=>{
            return (0, _channel.buildChannel)(elem);
        });
    }, []);
    if (version === "short") {
        return channels.map((elem)=>elem.value);
    }
    return channels;
}
