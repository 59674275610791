"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    skeletonStyle: function() {
        return skeletonStyle;
    },
    skeletonTextStyle: function() {
        return skeletonTextStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const skeletonStyle = (0, _tva.tva)({
    base: "w-full h-full",
    variants: {
        variant: {
            sharp: "rounded-none",
            circular: "rounded-full",
            rounded: "rounded-md"
        },
        speed: {
            1: "duration-75",
            2: "duration-100",
            3: "duration-150",
            4: "duration-200"
        }
    }
});
const skeletonTextStyle = (0, _tva.tva)({
    base: "rounded-sm w-full",
    variants: {
        speed: {
            1: "duration-75",
            2: "duration-100",
            3: "duration-150",
            4: "duration-200"
        },
        gap: {
            1: "gap-1",
            2: "gap-2",
            3: "gap-3",
            4: "gap-4"
        }
    }
});
