"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useConfirmModal", {
    enumerable: true,
    get: function() {
        return useConfirmModal;
    }
});
const _react = require("react");
const _AppProvider = require("../../providers/AppProvider");
const _useCloseConfirmModal = require("../useCloseConfirmModal");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function useConfirmModal(props) {
    const { confirmModal, setConfirmModal } = (0, _react.useContext)(_AppProvider.AppContext);
    const propsRef = (0, _react.useRef)(props);
    propsRef.current = props;
    const onHide = (0, _useCloseConfirmModal.useCloseConfirmModal)(propsRef.current.onClose);
    // dangerously update the same state where this hook could be used multiple
    // places which could end up wrongly display a modal
    // useEffect(() => {
    //     if (confirmModal?.id && confirmModal?.id === idRef.current) {
    //         // allow to update/refresh the modal
    //         // this trigger the modal
    //     }
    // }, [confirmModal?.id, props]);
    const onOpen = (0, _react.useCallback)(()=>{
        setConfirmModal(_object_spread_props(_object_spread({}, propsRef.current), {
            show: true,
            onHide
        }));
    }, [
        onHide,
        setConfirmModal
    ]);
    const refresh = (0, _react.useCallback)((values)=>{
        setConfirmModal((prev)=>{
            return _object_spread({}, prev, values !== null && values !== void 0 ? values : propsRef.current);
        });
    }, [
        setConfirmModal
    ]);
    return (0, _react.useMemo)(()=>[
            onOpen,
            refresh,
            onHide,
            confirmModal
        ], [
        onOpen,
        refresh,
        onHide,
        confirmModal
    ]);
}
