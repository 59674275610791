"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    formControlErrorIconStyle: function() {
        return formControlErrorIconStyle;
    },
    formControlErrorStyle: function() {
        return formControlErrorStyle;
    },
    formControlErrorTextStyle: function() {
        return formControlErrorTextStyle;
    },
    formControlHelperStyle: function() {
        return formControlHelperStyle;
    },
    formControlHelperTextStyle: function() {
        return formControlHelperTextStyle;
    },
    formControlLabelAstrickStyle: function() {
        return formControlLabelAstrickStyle;
    },
    formControlLabelStyle: function() {
        return formControlLabelStyle;
    },
    formControlLabelTextStyle: function() {
        return formControlLabelTextStyle;
    },
    formControlStyle: function() {
        return formControlStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const formControlStyle = (0, _tva.tva)({
    base: "flex flex-col z-auto",
    variants: {
        size: {
            sm: "",
            md: "",
            lg: ""
        }
    }
});
const formControlErrorIconStyle = (0, _tva.tva)({
    base: "font-body text-error-600 fill-none",
    variants: {}
});
const formControlErrorStyle = (0, _tva.tva)({
    base: "flex flex-row justify-start items-center mt-2 gap-1"
});
const formControlErrorTextStyle = (0, _tva.tva)({
    base: "font-body text-sm text-error-600 font-normal leading-sm",
    variants: {}
});
const formControlHelperStyle = (0, _tva.tva)({
    base: "flex flex-row justify-start items-center mt-2"
});
const formControlHelperTextStyle = (0, _tva.tva)({
    base: "font-body text-xs text-blueGray-400 leading-sm",
    variants: {}
});
const formControlLabelStyle = (0, _tva.tva)({
    base: "flex flex-row justify-start items-center mb-1"
});
const formControlLabelTextStyle = (0, _tva.tva)({
    base: "font-body text-xs font-medium text-blueGray-400 leading-sm",
    variants: {}
});
const formControlLabelAstrickStyle = (0, _tva.tva)({
    base: "font-body text-xs font-medium text-error-600 leading-sm",
    variants: {}
});
