"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useDirectBookingService", {
    enumerable: true,
    get: function() {
        return useDirectBookingService;
    }
});
const _react = require("react");
const _base = require("../../../services/base");
const _contexts = require("../../contexts");
const useDirectBookingService = ()=>{
    const http = (0, _react.useContext)(_contexts.HTTPContext);
    const base = (0, _react.useMemo)(()=>new _base.Base(http), [
        http
    ]);
    return base;
};
