"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "updateOptions", {
    enumerable: true,
    get: function() {
        return updateOptions;
    }
});
const defaultOptions = [
    {
        value: "{{Listing Title}}",
        label: "Listing Title"
    },
    {
        value: "{{Listing Nickname}}",
        label: "Listing Nickname"
    },
    {
        value: "{{Channel Listing ID}}",
        label: "Channel Listing ID"
    },
    {
        value: "{{Host Tools Listing ID}}",
        label: "Host Tools Listing ID"
    },
    {
        value: "{{Listing Address}}",
        label: "Listing Address"
    },
    {
        value: "{{Guidebook URL}}",
        label: "Guidebook URL"
    },
    {
        value: "{{Guest First Name}}",
        label: "Guest First Name"
    },
    {
        value: "{{Check-In Date}}",
        label: "Check-In Date"
    },
    {
        value: "{{Check-In Time}}",
        label: "Check-In Time"
    },
    {
        value: "{{Check-Out Date}}",
        label: "Check-Out Date"
    },
    {
        value: "{{Check-Out Time}}",
        label: "Check-Out Time"
    },
    {
        value: "{{Check-In Date|YYYY-MM-DD}}",
        label: "Check-In Date Custom Format"
    },
    {
        value: "{{Check-In Time|HH}}",
        label: "Check-In Time Custom Format"
    },
    {
        value: "{{Check-Out Date|YYYY-MM-DD}}",
        label: "Check-Out Date Custom Format"
    },
    {
        value: "{{Check-Out Time|HH}}",
        label: "Check-Out Time Custom Format"
    },
    {
        value: "{{Number of Nights}}",
        label: "Number of Nights"
    },
    {
        value: "{{Number of Guests}}",
        label: "Number of Guests"
    },
    {
        value: "{{Notes}}",
        label: "Internal Notes"
    },
    {
        value: "{{Channel}}",
        label: "Channel"
    }
];
const priorityOrder = [
    "{{Guest First Name}}",
    "{{Guest Last Name}}",
    "{{Guest Phone}}",
    "{{Guest Phone|4}}",
    "{{Guest Lock Code}}",
    "{{Check-In Date}}",
    "{{Check-In Date|YYYY-MM-DD}}",
    "{{Previous Check-In Date}}",
    "{{Check-In Time|HH}}",
    "{{Check-In Time}}",
    "{{Check-Out Date}}",
    "{{Check-Out Date|YYYY-MM-DD}}",
    "{{Previous Check-Out Date}}",
    "{{Check-Out Time|HH}}",
    "{{Check-Out Time}}",
    "{{Number of Nights}}",
    "{{Previous Number of Nights}}",
    "{{Number of Guests}}",
    "{{Previous Number of Guests}}",
    "{{Notes}}",
    "{{Listing Title}}",
    "{{Listing Nickname}}",
    "{{Channel Listing ID}}",
    "{{Host Tools Listing ID}}",
    "{{Listing Address}}",
    "{{Guidebook URL}}",
    "{{Confirmation Code}}",
    "{{Total Guest Payment}}",
    "{{Total Host Payout}}",
    "{{Channel}}"
];
function updateOptions(event, userTags) {
    let userTagsOptions = [];
    if (event !== "all") {
        userTagsOptions = userTags.map((userTag)=>{
            return {
                value: `{{${userTag.name}}}`,
                label: userTag.name || ""
            };
        });
    }
    const options = [
        ...userTagsOptions,
        ...defaultOptions
    ];
    if (event === "checkin" || event === "checkout" || event === "occupied" || event === "booking" || event === "checkinChanged" || event === "checkoutChanged" || event === "cancelled" || event === "all") {
        options.splice(3, 0, {
            value: "{{Guest Last Name}}",
            label: "Guest Last Name"
        });
        options.push({
            value: "{{Confirmation Code}}",
            label: "Confirmation Code"
        });
        options.push({
            value: "{{Guest Phone}}",
            label: "Guest Phone Number"
        });
        options.push({
            value: "{{Guest Phone|4}}",
            label: "Guest's Phone Number Custom Length"
        });
        options.push({
            value: "{{Guest Lock Code}}",
            label: "Guest's Lock Code (Last 4 of Phone Number)"
        });
        options.push({
            value: "{{Total Guest Payment}}",
            label: "Guest's Total Payment (Number Only)"
        });
        options.push({
            value: "{{Total Host Payout}}",
            label: "Host's Total Payout (Number Only)"
        });
    }
    if (event === "checkinChanged" || event === "all") {
        options.push({
            value: "{{Previous Check-In Date}}",
            label: "Previous Check-In Date"
        });
    }
    if (event === "checkoutChanged" || event === "all") {
        options.push({
            value: "{{Previous Check-Out Date}}",
            label: "Previous Check-Out Date"
        });
    }
    if (event === "checkinChanged" || event === "all") {
        options.push({
            value: "{{Previous Number of Nights}}",
            label: "Previous Number of Nights"
        });
    }
    if (event === "all") {
        options.push({
            value: "{{Previous Number of Guests}}",
            label: "Previous Number of Guests"
        });
    }
    options.sort((opt1, opt2)=>{
        if (priorityOrder.indexOf(opt1.value) > priorityOrder.indexOf(opt2.value)) {
            return 1;
        }
        if (priorityOrder.indexOf(opt1.value) < priorityOrder.indexOf(opt2.value)) {
            return -1;
        }
        return 0;
    });
    return options;
}
