"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useLastPost", {
    enumerable: true,
    get: function() {
        return useLastPost;
    }
});
const _react = require("react");
function useLastPost(posts, order = "asc") {
    return (0, _react.useMemo)(()=>{
        const postsOnly = posts === null || posts === void 0 ? void 0 : posts.reduce((result, post)=>{
            if (post.post.type === "post") {
                result.push(post.post.data);
            }
            return result;
        }, []);
        return (postsOnly === null || postsOnly === void 0 ? void 0 : postsOnly.length) ? postsOnly[order === "desc" ? 0 : postsOnly.length - 1] : undefined;
    }, [
        order,
        posts
    ]);
}
