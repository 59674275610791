"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _AppProvider = require("../../../../frontend/src/react/providers/AppProvider");
const _react = require("react");
const useNewDesign = ()=>{
    const { isNewDesignEnabled } = (0, _react.useContext)(_AppProvider.AppContext);
    return isNewDesignEnabled;
};
const _default = useNewDesign;
