"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getItemLayout", {
    enumerable: true,
    get: function() {
        return getItemLayout;
    }
});
const _constant = require("./constant");
const getItemLayout = (_data, index)=>({
        length: _constant.MAX_ITEM_HEIGHT,
        offset: _constant.MAX_ITEM_HEIGHT * index,
        index
    });
