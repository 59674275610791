"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useListingGroup", {
    enumerable: true,
    get: function() {
        return useListingGroup;
    }
});
const _react = require("react");
const _contexts = require("../../contexts");
function useListingGroup(listingID) {
    const { listingGroups } = (0, _react.useContext)(_contexts.UserContext);
    return (0, _react.useMemo)(()=>{
        if (!listingID) {
            return;
        }
        if (typeof listingID === "string") {
            return listingGroups.find((elem)=>elem._id === listingID);
        }
        return listingGroups.filter((elem)=>{
            return Array.isArray(listingID) ? listingID.includes(elem._id) : listingID === elem._id;
        });
    }, [
        listingID,
        listingGroups
    ]);
}
