"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _frontend = require("../../../../../../frontend/src/index");
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
// not sure why web server restarts as soon as saving this file?
const useFetchListingsFromAccount = (accountID)=>{
    const listingService = (0, _frontend.useListingService)();
    return (0, _reactquery.useQuery)({
        queryKey: [
            "listingsFromChannel",
            accountID
        ],
        retry: 0,
        staleTime: 1000 * 60 * 3,
        queryFn: (0, _react.useCallback)(async ()=>{
            if (!accountID) {
                return Promise.resolve([]);
            }
            return listingService.fetchListingsFromAccount(accountID);
        }, [
            accountID,
            listingService
        ])
    });
};
const _default = useFetchListingsFromAccount;
