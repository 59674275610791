"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    MONTHS_HEIGHT: function() {
        return MONTHS_HEIGHT;
    },
    NUMBER_OF_COLUMNS: function() {
        return NUMBER_OF_COLUMNS;
    },
    NUMBER_OF_ROWS: function() {
        return NUMBER_OF_ROWS;
    },
    SPACE_BETWEEN_ROW: function() {
        return SPACE_BETWEEN_ROW;
    },
    WEEKDAYS_HEIGHT: function() {
        return WEEKDAYS_HEIGHT;
    }
});
const NUMBER_OF_ROWS = 6;
const NUMBER_OF_COLUMNS = 7;
const WEEKDAYS_HEIGHT = 28;
const SPACE_BETWEEN_ROW = 4;
const MONTHS_HEIGHT = 40;
