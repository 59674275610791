"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return getTitlePricingRule;
    }
});
const _utils = require("../../../../../frontend/src/utils");
const _datefns = require("date-fns");
const _date = require("../../../admin/utils/date");
function getTitlePricingRule(pricingRule, currencySymbol, timeZone) {
    let title = "";
    const { amount: amountValue } = pricingRule;
    const { scale, channel, event, floatingPeriodStartDay, floatingPeriodLength, orphanPeriodLength, specificDatesStartDate, specificDatesEndDate, months: monthsValue, limitDays: limitDaysValue, availability, blockCheckin, blockCheckout, beforeReservationLength, afterReservationLength } = pricingRule;
    let amount = `${amountValue}`;
    const months = monthsValue || {};
    const limitDays = limitDaysValue || {};
    const scaleLowerCase = scale.toLowerCase();
    if (scaleLowerCase.search("percentage") >= 0) {
        amount += "%";
        if (scaleLowerCase.search("gradual") >= 0) {
            amount += " gradually";
        }
        if (scaleLowerCase.search("increase") >= 0) {
            amount = `+${amount}`;
        } else {
            amount = `-${amount}`;
        }
    } else if (scaleLowerCase.search("night") >= 0) {
        if (amountValue === 1) {
            amount = `Set booking length to a minimum of ${amount} night`;
        } else {
            amount = `Set booking length to a minimum of ${amount} nights`;
        }
    } else if (scaleLowerCase.search("availability") >= 0) {
        if (availability) {
            amount = "Block";
        } else {
            amount = "Available";
        }
    } else if (scaleLowerCase.search("blockcheckin") >= 0) {
        if (blockCheckin) {
            amount = "Block check-in";
        } else {
            amount = "Unblock check-in";
        }
    } else if (scaleLowerCase.search("blockcheckout") >= 0) {
        if (blockCheckout) {
            amount = "Block check-out";
        } else {
            amount = "Unblock check-out";
        }
    } else {
        amount = `${currencySymbol}${amount}`;
        if (scaleLowerCase.search("gradual") >= 0) {
            amount += " gradually";
        }
        if (scale === "minPrice") {
            amount += " min";
        } else if (scale === "maxPrice") {
            amount += " max";
        } else if (scaleLowerCase.search("increase") >= 0 && scale !== "fixedPrice") {
            amount = `+${amount}`;
        } else if (scale !== "fixedPrice") {
            amount = `-${amount}`;
        }
    }
    switch(event){
        case "all":
            title = `${amount} all dates`;
            break;
        case "floatingPeriod":
            if (floatingPeriodStartDay === 0) {
                title = amount;
            } else {
                title = `${amount} starts in ${floatingPeriodStartDay} days,`;
            }
            title = `${title} ends in ${(floatingPeriodStartDay || 0) + (floatingPeriodLength || 0)} days`;
            break;
        case "orphanPeriod":
            if (orphanPeriodLength === 1) {
                title = `${amount} for orphan periods of ${orphanPeriodLength} day or less`;
            } else {
                title = `${amount} for orphan periods of ${orphanPeriodLength} days or less`;
            }
            break;
        case "specificDates":
            title = `${amount} from ${(0, _date.isValidDateParam)(specificDatesStartDate) ? (0, _datefns.format)((0, _utils.fromLocalToZoned)(specificDatesStartDate, timeZone), "eee, MMM do yyyy") : ""} to ${(0, _date.isValidDateParam)(specificDatesEndDate) ? (0, _datefns.format)((0, _utils.fromLocalToZoned)(specificDatesEndDate, timeZone), "eee, MMM do yyyy") : ""}`;
            break;
        case "beforeReservation":
            title = `${amount} the ${beforeReservationLength && beforeReservationLength > 1 ? `${beforeReservationLength} days` : "day"} before a reservation`;
            break;
        case "afterReservation":
            title = `${amount} the ${afterReservationLength && afterReservationLength > 1 ? `${afterReservationLength} days` : "day"} after a reservation`;
            break;
        case "months":
            {
                const monthsList = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];
                let index = 0;
                let startingMonth = "";
                while(startingMonth === "" && index >= 0 && index <= 11){
                    if (months[monthsList[index].toLowerCase()]) {
                        startingMonth = monthsList[index];
                    }
                    index += 1;
                }
                index = 11;
                let endingMonth = "";
                while(endingMonth === "" && index >= 0 && index <= 11){
                    if (months[monthsList[index].toLowerCase()]) {
                        endingMonth = monthsList[index];
                    }
                    index -= 1;
                }
                if (startingMonth === endingMonth) {
                    title = `${amount} for the month of ${startingMonth}`;
                } else {
                    title = `${amount} from ${startingMonth} thru ${endingMonth}`;
                }
                break;
            }
        default:
            break;
    }
    // Days
    const limitDaysFormatted = Object.keys(limitDays).reduce((result, day)=>{
        if (limitDays[day]) {
            result.push(day.charAt(0).toUpperCase() + day.substring(1, 2));
        }
        return result;
    }, []);
    let limitDaysFormattedText = limitDaysFormatted.join(", ");
    if (limitDaysFormattedText === "Fr, Sa") {
        limitDaysFormattedText = "Weekends";
        title += ` (${limitDaysFormattedText})`;
    } else if (limitDaysFormattedText === "Mo, Tu, We, Th, Su") {
        limitDaysFormattedText = "Weekdays";
        title += ` (${limitDaysFormattedText})`;
    } else if (limitDaysFormattedText !== "Su, Mo, Tu, We, Th, Fr, Sa" && limitDaysFormattedText !== "Mo, Tu, We, Th, Fr, Sa, Su") {
        title += ` (${limitDaysFormattedText})`;
    }
    if (channel && channel !== "all") {
        if (channel === "HomeAway") {
            title = `VRBO: ${title}`;
        } else {
            title = `${channel}: ${title}`;
        }
    }
    return title;
}
