// __IS_BETA__ is created from `webpack` which is only generated with build mode
// this global variable is undefined during dev mode due to no webpack used instead of `ts-node`
"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "isBeta", {
    enumerable: true,
    get: function() {
        return isBeta;
    }
});
const isBeta = process.env.IS_BETA === "true";
