"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getDate", {
    enumerable: true,
    get: function() {
        return getDate;
    }
});
function getDate(date) {
    if (!date) {
        return undefined;
    }
    if (typeof date === "object") {
        if (date instanceof Date) {
            return date;
        }
        return date.toDate();
    }
    return new Date(date);
}
