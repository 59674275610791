"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    BedTypes: function() {
        return BedTypes;
    },
    CheckInMethods: function() {
        return CheckInMethods;
    },
    ImageTags: function() {
        return ImageTags;
    },
    PropertyTypes: function() {
        return PropertyTypes;
    },
    attributeOptions: function() {
        return attributeOptions;
    }
});
const PropertyTypes = [
    {
        name: "PCT101",
        description: "Townhome",
        icon: "Home"
    },
    {
        name: "PCT102",
        description: "Single Room",
        icon: "DoorClosed"
    },
    {
        name: "PCT103",
        description: "Double Room",
        icon: "Hotel"
    },
    {
        name: "PCT104",
        description: "Twin",
        icon: "Bed"
    },
    {
        name: "PCT105",
        description: "Twin/Double",
        icon: "Bed"
    },
    {
        name: "PCT106",
        description: "Triple Room",
        icon: "Hotel"
    },
    {
        name: "PCT107",
        description: "Quadruple",
        icon: "Building2"
    },
    {
        name: "PCT108",
        description: "Family",
        icon: "Users"
    },
    {
        name: "PCT109",
        description: "Suite",
        icon: "Home"
    },
    {
        name: "PCT110",
        description: "Studio",
        icon: "Building"
    },
    {
        name: "PCT111",
        description: "Bungalow",
        icon: "Home"
    },
    {
        name: "PCT112",
        description: "Private room",
        icon: "DoorClosed"
    },
    {
        name: "PCT113",
        description: "Shared room",
        icon: "UsersRound"
    },
    {
        name: "PCT114",
        description: "Cottage",
        icon: "Home"
    },
    {
        name: "PCT115",
        description: "Apart Hotel",
        icon: "Building"
    },
    {
        name: "PCT116",
        description: "Narrow boat",
        icon: "Sailboat"
    },
    {
        name: "PCT117",
        description: "Riad",
        icon: "Building"
    },
    {
        name: "PCT118",
        description: "Shepherd Hut",
        icon: "Home"
    },
    {
        name: "PCT119",
        description: "Tipi",
        icon: "Tent"
    },
    {
        name: "PCT12",
        description: "Cruise",
        icon: "Ship"
    },
    {
        name: "PCT120",
        description: "Tower",
        icon: "Castle"
    },
    {
        name: "PCT121",
        description: "Tree house",
        icon: "Trees"
    },
    {
        name: "PCT122",
        description: "Trullo",
        icon: "Home"
    },
    {
        name: "PCT123",
        description: "Watermill",
        icon: "Factory"
    },
    {
        name: "PCT124",
        description: "Windmill",
        icon: "Wind"
    },
    {
        name: "PCT125",
        description: "Yacht",
        icon: "Sailboat"
    },
    {
        name: "PCT126",
        description: "Yurt",
        icon: "Tent"
    },
    {
        name: "PCT127",
        description: "Log Cabin",
        icon: "Home"
    },
    {
        name: "PCT128",
        description: "Penthouse",
        icon: "Building2"
    },
    {
        name: "PCT14",
        description: "Ferry",
        icon: "Ship"
    },
    {
        name: "PCT15",
        description: "Guest farm",
        icon: "Tractor"
    },
    {
        name: "PCT16",
        description: "Guest house limited service",
        icon: "Home"
    },
    {
        name: "PCT18",
        description: "Holiday resort",
        icon: "PalmTree"
    },
    {
        name: "PCT19",
        description: "Hostel",
        icon: "Building"
    },
    {
        name: "PCT20",
        description: "Hotel",
        icon: "Building"
    },
    {
        name: "PCT21",
        description: "Inn",
        icon: "Building"
    },
    {
        name: "PCT22",
        description: "Lodge",
        icon: "Home"
    },
    {
        name: "PCT23",
        description: "Meeting resort",
        icon: "Building"
    },
    {
        name: "PCT25",
        description: "Mobile-home",
        icon: "Truck"
    },
    {
        name: "PCT26",
        description: "Monastery",
        icon: "Church"
    },
    {
        name: "PCT27",
        description: "Motel",
        icon: "Building"
    },
    {
        name: "PCT28",
        description: "Ranch",
        icon: "Tractor"
    },
    {
        name: "PCT29",
        description: "Residential apartment",
        icon: "Building"
    },
    {
        name: "PCT3",
        description: "Apartment",
        icon: "Building"
    },
    {
        name: "PCT30",
        description: "Resort",
        icon: "PalmTree"
    },
    {
        name: "PCT31",
        description: "Sailing ship",
        icon: "Sailboat"
    },
    {
        name: "PCT32",
        description: "Self catering accommodation",
        icon: "Utensils"
    },
    {
        name: "PCT33",
        description: "Tent",
        icon: "Tent"
    },
    {
        name: "PCT34",
        description: "Vacation home",
        icon: "Home"
    },
    {
        name: "PCT35",
        description: "Villa",
        icon: "Home"
    },
    {
        name: "PCT36",
        description: "Wildlife reserve",
        icon: "Trees"
    },
    {
        name: "PCT37",
        description: "Castle",
        icon: "Castle"
    },
    {
        name: "PCT4",
        description: "Bed and breakfast",
        icon: "Coffee"
    },
    {
        name: "PCT40",
        description: "Pension",
        icon: "Building"
    },
    {
        name: "PCT41",
        description: "Ski Chalet",
        icon: "Snowflake"
    },
    {
        name: "PCT44",
        description: "Boatel",
        icon: "Ship"
    },
    {
        name: "PCT45",
        description: "Boutique",
        icon: "Building"
    },
    {
        name: "PCT46",
        description: "Efficiency/studio",
        icon: "Building"
    },
    {
        name: "PCT5",
        description: "Cabin or bungalow",
        icon: "Home"
    },
    {
        name: "PCT50",
        description: "Recreational vehicle park",
        icon: "Truck"
    },
    {
        name: "PCT51",
        description: "Charm hotel",
        icon: "Building"
    },
    {
        name: "PCT52",
        description: "Manor",
        icon: "Building2"
    },
    {
        name: "PCT6",
        description: "Campground",
        icon: "Tent"
    },
    {
        name: "PCT7",
        description: "Chalet",
        icon: "Home"
    },
    {
        name: "PCT8",
        description: "Condominium",
        icon: "Building"
    }
];
const CheckInMethods = [
    {
        name: "doorman",
        description: "",
        icon: "UserRoundCog"
    },
    {
        name: "lock_box",
        description: "",
        icon: "BoxSelect"
    },
    {
        name: "smart_lock",
        description: "",
        icon: "Lock"
    },
    {
        name: "keypad",
        description: "",
        icon: "KeyRound"
    },
    {
        name: "in_person_meet",
        description: "",
        icon: "UserCheck"
    },
    {
        name: "other",
        description: "",
        icon: "MoreHorizontal"
    },
    {
        name: "front_desk",
        description: "",
        icon: "BookOpen"
    },
    {
        name: "secret_spot",
        description: "",
        icon: "KeySquare"
    },
    {
        name: "instruction_contact_us",
        description: "",
        icon: "HelpCircle"
    }
];
const ImageTags = [
    {
        Key: "1",
        Value: "Shower",
        icon: "Shower"
    },
    {
        Key: "2",
        Value: "Toilet",
        icon: "Bath"
    },
    {
        Key: "3",
        Value: "Property building",
        icon: "Building"
    },
    {
        Key: "4",
        Value: "patio",
        icon: "Umbrella"
    },
    {
        Key: "5",
        Value: "Nearby landmark",
        icon: "Landmark"
    },
    {
        Key: "6",
        Value: "Staff",
        icon: "Users"
    },
    {
        Key: "7",
        Value: "Restaurant/places to eat",
        icon: "Utensils"
    },
    {
        Key: "8",
        Value: "Communal lounge/ TV room",
        icon: "Tv"
    },
    {
        Key: "10",
        Value: "Facade/entrance",
        icon: "DoorOpen"
    },
    {
        Key: "43",
        Value: "People",
        icon: "Users"
    },
    {
        Key: "11",
        Value: "Spring",
        icon: "Flower"
    },
    {
        Key: "13",
        Value: "Bed",
        icon: "Bed"
    },
    {
        Key: "14",
        Value: "Off site",
        icon: "MapPin"
    },
    {
        Key: "37",
        Value: "Food close-up",
        icon: "Pizza"
    },
    {
        Key: "41",
        Value: "Day",
        icon: "Sun"
    },
    {
        Key: "42",
        Value: "Night",
        icon: "Moon"
    },
    {
        Key: "50",
        Value: "Property logo or sign",
        icon: "FileImage"
    },
    {
        Key: "55",
        Value: "Neighbourhood",
        icon: "HomeIcon"
    },
    {
        Key: "61",
        Value: "Natural landscape",
        icon: "Mountain"
    },
    {
        Key: "70",
        Value: "Activities",
        icon: "Activity"
    },
    {
        Key: "74",
        Value: "Bird's eye view",
        icon: "Bird"
    },
    {
        Key: "81",
        Value: "Winter",
        icon: "Snowflake"
    },
    {
        Key: "82",
        Value: "Summer",
        icon: "Sun"
    },
    {
        Key: "87",
        Value: "BBQ facilities",
        icon: "Flame"
    },
    {
        Key: "89",
        Value: "Billiard",
        icon: "CircleDot"
    },
    {
        Key: "90",
        Value: "Bowling",
        icon: "CircleDashed"
    },
    {
        Key: "94",
        Value: "Casino",
        icon: "Dices"
    },
    {
        Key: "95",
        Value: "Place of worship",
        icon: "Church"
    },
    {
        Key: "96",
        Value: "Children play ground",
        icon: "Baby"
    },
    {
        Key: "97",
        Value: "Darts",
        icon: "Target"
    },
    {
        Key: "100",
        Value: "Fishing",
        icon: "Fish"
    },
    {
        Key: "102",
        Value: "Game Room",
        icon: "Gamepad"
    },
    {
        Key: "103",
        Value: "Garden",
        icon: "Flower"
    },
    {
        Key: "104",
        Value: "Golf course",
        icon: "Golf"
    },
    {
        Key: "106",
        Value: "Horse-riding",
        icon: "Horse"
    },
    {
        Key: "107",
        Value: "Hot Spring Bath",
        icon: "Waves"
    },
    {
        Key: "141",
        Value: "Table tennis",
        icon: "Racquet"
    },
    {
        Key: "108",
        Value: "Hot Tub",
        icon: "Droplets"
    },
    {
        Key: "112",
        Value: "Karaoke",
        icon: "Music"
    },
    {
        Key: "113",
        Value: "Library",
        icon: "Library"
    },
    {
        Key: "114",
        Value: "Massage",
        icon: "Spa"
    },
    {
        Key: "115",
        Value: "Minigolf",
        icon: "Golf"
    },
    {
        Key: "116",
        Value: "Nightclub / DJ",
        icon: "Music"
    },
    {
        Key: "124",
        Value: "Sauna",
        icon: "Droplets"
    },
    {
        Key: "125",
        Value: "On-site shops",
        icon: "ShoppingBag"
    },
    {
        Key: "128",
        Value: "Ski School",
        icon: "Snowflake"
    },
    {
        Key: "131",
        Value: "Skiing",
        icon: "Snowflake"
    },
    {
        Key: "137",
        Value: "Squash",
        icon: "Racquet"
    },
    {
        Key: "133",
        Value: "Snorkeling",
        icon: "Swim"
    },
    {
        Key: "134",
        Value: "Solarium",
        icon: "Sun"
    },
    {
        Key: "143",
        Value: "Steam room",
        icon: "CloudFog"
    },
    {
        Key: "153",
        Value: "Bathroom",
        icon: "Bath"
    },
    {
        Key: "154",
        Value: "TV and multimedia",
        icon: "Tv"
    },
    {
        Key: "155",
        Value: "Coffee/tea facilities",
        icon: "Coffee"
    },
    {
        Key: "156",
        Value: "View (from property/room)",
        icon: "Eye"
    },
    {
        Key: "157",
        Value: "Balcony/Terrace",
        icon: "Columns"
    },
    {
        Key: "158",
        Value: "Kitchen or kitchenette",
        icon: "Utensils"
    },
    {
        Key: "159",
        Value: "Living room",
        icon: "Sofa"
    },
    {
        Key: "160",
        Value: "Lobby or reception",
        icon: "BookOpen"
    },
    {
        Key: "161",
        Value: "Lounge or bar",
        icon: "Wine"
    },
    {
        Key: "164",
        Value: "Spa and wellness centre/facili",
        icon: "Spa"
    },
    {
        Key: "165",
        Value: "Fitness centre/facilities",
        icon: "Dumbbell"
    },
    {
        Key: "167",
        Value: "Food and drinks",
        icon: "Utensils"
    },
    {
        Key: "172",
        Value: "Other",
        icon: "MoreHorizontal"
    },
    {
        Key: "173",
        Value: "Photo of the whole room",
        icon: "Image"
    },
    {
        Key: "177",
        Value: "Business facilities",
        icon: "Briefcase"
    },
    {
        Key: "178",
        Value: "Banquet/Function facilities",
        icon: "Users"
    },
    {
        Key: "179",
        Value: "Decorative detail",
        icon: "Paintbrush"
    },
    {
        Key: "182",
        Value: "Seating area",
        icon: "Sofa"
    },
    {
        Key: "183",
        Value: "Floor plan",
        icon: "LayoutTemplate"
    },
    {
        Key: "184",
        Value: "Dining area",
        icon: "Utensils"
    },
    {
        Key: "185",
        Value: "Beach",
        icon: "Umbrella"
    },
    {
        Key: "186",
        Value: "Aqua park",
        icon: "Waves"
    },
    {
        Key: "187",
        Value: "Tennis court",
        icon: "Activity"
    },
    {
        Key: "188",
        Value: "Windsurfing",
        icon: "Wind" // not sure, alternatives: Sailboat
    },
    {
        Key: "189",
        Value: "Canoeing",
        icon: "Kayak" // not sure, alternatives: Sailboat, Ship
    },
    {
        Key: "190",
        Value: "Hiking",
        icon: "Mountain"
    },
    {
        Key: "191",
        Value: "Cycling",
        icon: "Bike"
    },
    {
        Key: "192",
        Value: "Diving",
        icon: "Droplets" // not sure
    },
    {
        Key: "193",
        Value: "Kids's club",
        icon: "Baby"
    },
    {
        Key: "194",
        Value: "Evening entertainment",
        icon: "Music"
    },
    {
        Key: "197",
        Value: "Logo/Certificate/Sign",
        icon: "Award"
    },
    {
        Key: "198",
        Value: "Animals",
        icon: "Cat" // alternatives: Dog, Fish, Squirrel
    },
    {
        Key: "199",
        Value: "Bedroom",
        icon: "Bed"
    },
    {
        Key: "204",
        Value: "Communal kitchen",
        icon: "CookingPot"
    },
    {
        Key: "205",
        Value: "Autumn",
        icon: "Leaf" // alternatives: Shrub, Trees
    },
    {
        Key: "240",
        Value: "On site",
        icon: "MapPin"
    },
    {
        Key: "241",
        Value: "Meeting/conference room",
        icon: "Users"
    },
    {
        Key: "242",
        Value: "Food",
        icon: "Utensils"
    },
    {
        Key: "245",
        Value: "Text overlay",
        icon: "Text"
    },
    {
        Key: "246",
        Value: "Pets",
        icon: "Paw" // not sure, alternatives: Dog
    },
    {
        Key: "247",
        Value: "Guests",
        icon: "UsersRound"
    },
    {
        Key: "248",
        Value: "City view",
        icon: "Building" // alternatives: Building2
    },
    {
        Key: "249",
        Value: "Garden view",
        icon: "Flower"
    },
    {
        Key: "250",
        Value: "Lake view",
        icon: "Waves" // alternatives: WaterOff, Droplets
    },
    {
        Key: "251",
        Value: "Landmark view",
        icon: "Landmark"
    },
    {
        Key: "252",
        Value: "Mountain view",
        icon: "Mountain"
    },
    {
        Key: "253",
        Value: "Pool view",
        icon: "Waves" // alternatives: Droplets
    },
    {
        Key: "254",
        Value: "River view",
        icon: "Waves" // alternatives: WaterOff
    },
    {
        Key: "255",
        Value: "Sea view",
        icon: "Ship" // alternatives: Sailboat, Waves
    },
    {
        Key: "256",
        Value: "Street view",
        icon: "Road" // not sure, alternatives: Navigation
    },
    {
        Key: "257",
        Value: "Area and facilities",
        icon: "MapPin" // alternatives: Map
    },
    {
        Key: "258",
        Value: "Supermarket/grocery shop",
        icon: "ShoppingCart"
    },
    {
        Key: "259",
        Value: "Shopping Area",
        icon: "ShoppingBag"
    },
    {
        Key: "260",
        Value: "Swimming pool",
        icon: "Droplets" // alternatives: Waves
    },
    {
        Key: "261",
        Value: "Sports",
        icon: "Activity"
    },
    {
        Key: "262",
        Value: "Entertainment",
        icon: "Music" // alternatives: Theater
    },
    {
        Key: "263",
        Value: "Meals",
        icon: "Utensils"
    },
    {
        Key: "264",
        Value: "Breakfast",
        icon: "Coffee"
    },
    {
        Key: "265",
        Value: "Continental breakfast",
        icon: "Coffee" // alternatives: Croissant
    },
    {
        Key: "266",
        Value: "Buffet breakfast",
        icon: "Utensils"
    },
    {
        Key: "267",
        Value: "Asian breakfast",
        icon: "Utensils" // not sure
    },
    {
        Key: "268",
        Value: "Italian breakfast",
        icon: "Utensils" // not sure
    },
    {
        Key: "269",
        Value: "English/Irish breakfast",
        icon: "Utensils" // not sure
    },
    {
        Key: "270",
        Value: "American breakfast",
        icon: "Utensils" // not sure
    },
    {
        Key: "271",
        Value: "Lunch",
        icon: "Utensils"
    },
    {
        Key: "272",
        Value: "Dinner",
        icon: "Utensils"
    },
    {
        Key: "273",
        Value: "Drinks",
        icon: "Wine"
    },
    {
        Key: "274",
        Value: "Alcoholic drinks",
        icon: "Wine"
    },
    {
        Key: "275",
        Value: "Non alcoholic drinks",
        icon: "CupSoda"
    },
    {
        Key: "276",
        Value: "Seasons",
        icon: "Calendar" // alternatives: Sun, Cloud
    },
    {
        Key: "277",
        Value: "Time of day",
        icon: "Clock"
    },
    {
        Key: "278",
        Value: "Location",
        icon: "MapPin"
    },
    {
        Key: "279",
        Value: "Sunrise",
        icon: "Sunrise"
    },
    {
        Key: "280",
        Value: "Sunset",
        icon: "Sunset"
    },
    {
        Key: "281",
        Value: "children",
        icon: "Baby"
    },
    {
        Key: "282",
        Value: "young children",
        icon: "Baby"
    },
    {
        Key: "283",
        Value: "older children",
        icon: "PersonStanding"
    },
    {
        Key: "284",
        Value: "group of guests",
        icon: "Users"
    },
    {
        Key: "285",
        Value: "cot",
        icon: "Bed"
    },
    {
        Key: "286",
        Value: "bunk bed",
        icon: "Bed"
    },
    {
        Key: "287",
        Value: "Certificate/Award",
        icon: "Award"
    },
    {
        Key: "288",
        Value: "ADAM",
        icon: "User" // not sure
    },
    {
        Key: "289",
        Value: "Open Air Bath",
        icon: "Bath"
    },
    {
        Key: "290",
        Value: "Public Bath",
        icon: "Bath"
    },
    {
        Key: "291",
        Value: "Family",
        icon: "Users"
    }
];
const BedTypes = [
    {
        value: "RMA58",
        label: "King bed",
        icon: "Bed"
    },
    {
        value: "RMA86",
        label: "Queen bed",
        icon: "Bed"
    },
    {
        value: "RMA102",
        label: "Sofa bed",
        icon: "Sofa"
    },
    {
        value: "RMA113",
        label: "Twin bed",
        icon: "Bed"
    },
    {
        value: "RMA200",
        label: "Futon",
        icon: "Bed"
    },
    {
        value: "RMA201",
        label: "Murphy bed",
        icon: "Bed"
    },
    {
        value: "RMA203",
        label: "Single bed",
        icon: "Bed"
    },
    {
        value: "RMA6032",
        label: "Bunk bed",
        icon: "Bed"
    },
    {
        value: "RMA33",
        label: "Double bed",
        icon: "Bed"
    },
    {
        value: "RMA26",
        label: "Cribs",
        icon: "Bed"
    },
    {
        value: "RMA6038",
        label: "Extra bed",
        icon: "Bed"
    },
    {
        value: "RMA6118",
        label: "Couch",
        icon: "Sofa"
    },
    {
        value: "RMA6119",
        label: "Air mattress",
        icon: "Bed"
    },
    {
        value: "RMA6120",
        label: "Floor mattress",
        icon: "Bed"
    },
    {
        value: "RMA6121",
        label: "Toddler bed",
        icon: "Bed"
    },
    {
        value: "RMA6122",
        label: "Hammock",
        icon: "Bed"
    }
];
const attributeOptions = [
    {
        value: "ACC111",
        label: "Arboretum",
        icon: "Trees"
    },
    {
        value: "ACC112",
        label: "Autumn foliage",
        icon: "Leaf"
    },
    {
        value: "ACC113",
        label: "Cave",
        icon: "Mountain" // not sure
    },
    {
        value: "ACC114",
        label: "Duty Free",
        icon: "ShoppingBag"
    },
    {
        value: "ACC115",
        label: "Nude beach",
        icon: "Umbrella" // not sure
    },
    {
        value: "ACC116",
        label: "Rain forest",
        icon: "Trees"
    },
    {
        value: "ACC117",
        label: "Reef",
        icon: "Fish"
    },
    {
        value: "ACC118",
        label: "Ruins",
        icon: "Landmark"
    },
    {
        value: "ACC119",
        label: "Outdoor lanai gazebo covered",
        icon: "Home" // not sure
    },
    {
        value: "ACC120",
        label: "Volcano",
        icon: "Flame"
    },
    {
        value: "ACC123",
        label: "Rural",
        icon: "Trees"
    },
    {
        value: "ACC14",
        label: "Church",
        icon: "Church"
    },
    {
        value: "ACC24",
        label: "Hospital",
        icon: "Syringe" // alternatives: Stethoscope
    },
    {
        value: "ACC25",
        label: "Lake",
        icon: "Droplets"
    },
    {
        value: "ACC27",
        label: "Library",
        icon: "Library"
    },
    {
        value: "ACC28",
        label: "Marina",
        icon: "Anchor"
    },
    {
        value: "ACC29",
        label: "Market",
        icon: "ShoppingCart"
    },
    {
        value: "ACC31",
        label: "Mountain",
        icon: "Mountain"
    },
    {
        value: "ACC32",
        label: "Museum",
        icon: "Landmark"
    },
    {
        value: "ACC33",
        label: "Ocean",
        icon: "Waves" // alternatives: Ship
    },
    {
        value: "ACC39",
        label: "Recreation center",
        icon: "Activity"
    },
    {
        value: "ACC40",
        label: "Resort",
        icon: "Umbrella" // alternatives: Hotel, Building
    },
    {
        value: "ACC41",
        label: "Restaurant",
        icon: "Utensils"
    },
    {
        value: "ACC42",
        label: "River",
        icon: "Waves"
    },
    {
        value: "ACC45",
        label: "Ski area",
        icon: "Mountain"
    },
    {
        value: "ACC47",
        label: "Store",
        icon: "ShoppingCart"
    },
    {
        value: "ACC5",
        label: "Beach",
        icon: "Umbrella"
    },
    {
        value: "ACC50",
        label: "Movie Theater",
        icon: "Theater" // alternatives: Film
    },
    {
        value: "ACC54",
        label: "Water park",
        icon: "Droplets" // alternatives: Waves
    },
    {
        value: "ACC55",
        label: "Waterfront",
        icon: "Waves"
    },
    {
        value: "ACC56",
        label: "Winery",
        icon: "Wine"
    },
    {
        value: "ACC57",
        label: "Zoo",
        icon: "Cat" // alternatives: Dog, Paw
    },
    {
        value: "ACC59",
        label: "Festival",
        icon: "PartyPopper"
    },
    {
        value: "ACC73",
        label: "Bar",
        icon: "Wine" // alternatives: Beer
    },
    {
        value: "ACC74",
        label: "Bay",
        icon: "Waves" // alternatives: Ship
    },
    {
        value: "ACC82",
        label: "Synagogue",
        icon: "Building" // not sure
    },
    {
        value: "ACC90",
        label: "Botanical garden",
        icon: "Flower"
    },
    {
        value: "ACC95",
        label: "Natural attraction",
        icon: "Mountain" // not sure
    },
    {
        value: "HAC1",
        label: "24 Hour Reception Desk",
        icon: "Bell"
    },
    {
        value: "HAC101",
        label: "Wheelchair access",
        icon: "Accessibility"
    },
    {
        value: "HAC116",
        label: "Accessible parking",
        icon: "Car"
    },
    {
        value: "HAC122",
        label: "Shops and commercial services",
        icon: "ShoppingBag"
    },
    {
        value: "HAC14",
        label: "Business library",
        icon: "Library"
    },
    {
        value: "HAC149",
        label: "Grocery shopping service available",
        icon: "ShoppingCart"
    },
    {
        value: "HAC15",
        label: "Car rental desk",
        icon: "Car"
    },
    {
        value: "HAC154",
        label: "Medical Facilities Service",
        icon: "Stethoscope"
    },
    {
        value: "HAC156",
        label: "All-inclusive meal plan",
        icon: "Utensils"
    },
    {
        value: "HAC157",
        label: "Buffet breakfast",
        icon: "Coffee"
    },
    {
        value: "HAC159",
        label: "Continental breakfast",
        icon: "Coffee"
    },
    {
        value: "HAC16",
        label: "Casino",
        icon: "Dice1" // alternatives: Coins
    },
    {
        value: "HAC160",
        label: "Full meal plan",
        icon: "Utensils"
    },
    {
        value: "HAC165",
        label: "Lounges/bars",
        icon: "Wine"
    },
    {
        value: "HAC168",
        label: "Onsite laundry",
        icon: "Shirt" // not sure
    },
    {
        value: "HAC173",
        label: "Breakfast served in restaurant",
        icon: "Coffee"
    },
    {
        value: "HAC176",
        label: "Full service housekeeping",
        icon: "Bed"
    },
    {
        value: "HAC186",
        label: "Street side parking",
        icon: "Car"
    },
    {
        value: "HAC193",
        label: "Children's play area",
        icon: "Baby"
    },
    {
        value: "HAC195",
        label: "Disco",
        icon: "Music"
    },
    {
        value: "HAC196",
        label: "Early check-in",
        icon: "Clock"
    },
    {
        value: "HAC197",
        label: "Locker room",
        icon: "Lock"
    },
    {
        value: "HAC198",
        label: "Non-smoking rooms (generic)",
        icon: "CigaretteOff"
    },
    {
        value: "HAC202",
        label: "Bicycle rentals",
        icon: "Bike"
    },
    {
        value: "HAC204",
        label: "Late check-out available",
        icon: "Clock"
    },
    {
        value: "HAC21",
        label: "Coin operated laundry",
        icon: "Shirt" // alternatives: Coins
    },
    {
        value: "HAC22",
        label: "Concierge desk",
        icon: "Bell"
    },
    {
        value: "HAC226",
        label: "Catering services",
        icon: "Utensils"
    },
    {
        value: "HAC228",
        label: "Business center",
        icon: "Briefcase"
    },
    {
        value: "HAC230",
        label: "Secured parking",
        icon: "ShieldCheck"
    },
    {
        value: "HAC231",
        label: "Racquetball",
        icon: "Activity" // not sure
    },
    {
        value: "HAC232",
        label: "Snow sports",
        icon: "Snowflake"
    },
    {
        value: "HAC233",
        label: "Tennis court",
        icon: "Activity"
    },
    {
        value: "HAC234",
        label: "Water sports",
        icon: "Waves"
    },
    {
        value: "HAC236",
        label: "Golf",
        icon: "Trophy" // not sure
    },
    {
        value: "HAC237",
        label: "Horseback riding",
        icon: "Activity" // not sure
    },
    {
        value: "HAC238",
        label: "Oceanfront",
        icon: "Waves"
    },
    {
        value: "HAC239",
        label: "Beachfront",
        icon: "Umbrella"
    },
    {
        value: "HAC241",
        label: "Ironing board",
        icon: "Shirt" // not sure
    },
    {
        value: "HAC242",
        label: "Heated guest rooms",
        icon: "Thermometer"
    },
    {
        value: "HAC246",
        label: "Thalassotherapy",
        icon: "Bath" // not sure
    },
    {
        value: "HAC25",
        label: "Courtyard",
        icon: "Garden"
    },
    {
        value: "HAC254",
        label: "Connecting rooms",
        icon: "Link"
    },
    {
        value: "HAC26",
        label: "Currency exchange",
        icon: "Banknote"
    },
    {
        value: "HAC262",
        label: "Kitchenette",
        icon: "CookingPot"
    },
    {
        value: "HAC265",
        label: "Welcome drink",
        icon: "GlassWater"
    },
    {
        value: "HAC269",
        label: "Meeting rooms",
        icon: "Users"
    },
    {
        value: "HAC272",
        label: "Snow skiing",
        icon: "Snowflake"
    },
    {
        value: "HAC273",
        label: "Water skiing",
        icon: "Waves" // changed from Swim which is not in the list
    },
    {
        value: "HAC280",
        label: "Grocery store",
        icon: "ShoppingBag" // changed from ShoppingBasket
    },
    {
        value: "HAC282",
        label: "Airport shuttle service",
        icon: "Plane"
    },
    {
        value: "HAC283",
        label: "Luggage service",
        icon: "Luggage"
    },
    {
        value: "HAC29",
        label: "Door man",
        icon: "DoorOpen"
    },
    {
        value: "HAC292",
        label: "Newspaper",
        icon: "Newspaper"
    },
    {
        value: "HAC301",
        label: "Food and beverage discount",
        icon: "Percent"
    },
    {
        value: "HAC309",
        label: "Welcome gift",
        icon: "Gift"
    },
    {
        value: "HAC310",
        label: "Hypoallergenic rooms",
        icon: "Flower"
    },
    {
        value: "HAC312",
        label: "Smoke-free property",
        icon: "CigaretteOff"
    },
    {
        value: "HAC316",
        label: "Electric car charging stations",
        icon: "BatteryCharging"
    },
    {
        value: "HAC32",
        label: "Duty free shop",
        icon: "ShoppingBag"
    },
    {
        value: "HAC327",
        label: "Events ticket service",
        icon: "Ticket"
    },
    {
        value: "HAC33",
        label: "Elevators",
        icon: "ArrowUp" // changed from Elevator which is not in the list
    },
    {
        value: "HAC334",
        label: "Summer terrace",
        icon: "Umbrella" // changed from PalmTree which is not in the list
    },
    {
        value: "HAC34",
        label: "Executive floor",
        icon: "Briefcase"
    },
    {
        value: "HAC342",
        label: "Snack bar",
        icon: "Cookie"
    },
    {
        value: "HAC345",
        label: "Fitness center",
        icon: "Dumbbell"
    },
    {
        value: "HAC348",
        label: "Health and beauty services",
        icon: "Scissors"
    },
    {
        value: "HAC36",
        label: "Express check-in",
        icon: "CheckCheck" // changed from CheckCircle which is not in the list
    },
    {
        value: "HAC37",
        label: "Express check-out",
        icon: "LogOut"
    },
    {
        value: "HAC41",
        label: "Free airport shuttle",
        icon: "Plane"
    },
    {
        value: "HAC42",
        label: "Free parking",
        icon: "Car" // changed from ParkingSquare which is not in the list
    },
    {
        value: "HAC44",
        label: "Game room",
        icon: "Gamepad"
    },
    {
        value: "HAC45",
        label: "Gift/News stand",
        icon: "Gift"
    },
    {
        value: "HAC49",
        label: "Heated pool",
        icon: "ThermometerSun"
    },
    {
        value: "HAC5",
        label: "Air conditioning",
        icon: "Fan"
    },
    {
        value: "HAC50",
        label: "Housekeeping - daily",
        icon: "Sparkles"
    },
    {
        value: "HAC5033",
        label: "Ski Storage",
        icon: "Snowflake"
    },
    {
        value: "HAC51",
        label: "Housekeeping - weekly",
        icon: "Calendar"
    },
    {
        value: "HAC53",
        label: "Indoor parking",
        icon: "ParkingSquare"
    },
    {
        value: "HAC54",
        label: "Indoor pool",
        icon: "Pool"
    },
    {
        value: "HAC55",
        label: "Jacuzzi",
        icon: "ThermometerSun"
    },
    {
        value: "HAC60",
        label: "Live entertainment",
        icon: "Music"
    },
    {
        value: "HAC6002",
        label: "Chef Services",
        icon: "ChefHat"
    },
    {
        value: "HAC6008",
        label: "Ski rental",
        icon: "Snowflake"
    },
    {
        value: "HAC6010",
        label: "Child toddler seat",
        icon: "Baby"
    },
    {
        value: "HAC6013",
        label: "Photographer",
        icon: "Camera"
    },
    {
        value: "HAC6014",
        label: "Chauffeur/cars",
        icon: "Car"
    },
    {
        value: "HAC6019",
        label: "Soap/Shampoo",
        icon: "ShowerHead"
    },
    {
        value: "HAC6020",
        label: "Boat",
        icon: "Ship"
    },
    {
        value: "HAC6024",
        label: "Self checkin",
        icon: "Key"
    },
    {
        value: "HAC6025",
        label: "pack-n-play",
        icon: "Box"
    },
    {
        value: "HAC6026",
        label: "Other services staff",
        icon: "Users"
    },
    {
        value: "HAC6027",
        label: "Games",
        icon: "Gamepad2"
    },
    {
        value: "HAC6028",
        label: "Petanque",
        icon: "Circle"
    },
    {
        value: "HAC6029",
        label: "Car necessary",
        icon: "Car"
    },
    {
        value: "HAC6030",
        label: "Car not necessary",
        icon: "CarOff"
    },
    {
        value: "HAC6031",
        label: "Car recommended",
        icon: "Car"
    },
    {
        value: "HAC6032",
        label: "Synagogues",
        icon: "Building"
    },
    {
        value: "HAC6033",
        label: "EV charger",
        icon: "BatteryCharging"
    },
    {
        value: "HAC6034",
        label: "Long term stays allowed",
        icon: "Calendar"
    },
    {
        value: "HAC6035",
        label: "Cleaning before checkout",
        icon: "Sparkles"
    },
    {
        value: "HAC6036",
        label: "Beach essentials",
        icon: "Umbrella"
    },
    {
        value: "HAC6037",
        label: "Wide hallway clearance",
        icon: "MoveHorizontal"
    },
    {
        value: "HAC6038",
        label: "Home step free access",
        icon: "Home"
    },
    {
        value: "HAC6039",
        label: "Path to entrance lit at night",
        icon: "Flashlight"
    },
    {
        value: "HAC6040",
        label: "Home wide doorway",
        icon: "DoorOpen"
    },
    {
        value: "HAC6041",
        label: "Flat smooth pathway to front door",
        icon: "ArrowRight"
    },
    {
        value: "HAC6042",
        label: "Disabled parking spot",
        icon: "Wheelchair"
    },
    {
        value: "HAC6043",
        label: "HAC6043",
        icon: "Circle"
    },
    {
        value: "HAC6044",
        label: "Cycling Trips",
        icon: "Bike"
    },
    {
        value: "HAC6045",
        label: "Included housekeeping",
        icon: "Sparkles"
    },
    {
        value: "HAC6046",
        label: "Staffed Property",
        icon: "Users"
    },
    {
        value: "HAC6047",
        label: "Near a train/subway station",
        icon: "Train"
    },
    {
        value: "HAC6048",
        label: "Nearby local nightlife",
        icon: "Music"
    },
    {
        value: "HAC6049",
        label: "Shared residential building",
        icon: "Building2"
    },
    {
        value: "HAC6050",
        label: "24/7 Support",
        icon: "Headphones"
    },
    {
        value: "HAC6051",
        label: "Butler",
        icon: "UserCog"
    },
    {
        value: "HAC6052",
        label: "Concierge Services",
        icon: "Bell"
    },
    {
        value: "HAC6053",
        label: "In-person Check-in",
        icon: "UserCheck"
    },
    {
        value: "HAC6054",
        label: "Carbon Monoxide Detector + Smoke Detector",
        icon: "AlertCircle"
    },
    {
        value: "HAC6055",
        label: "Professionally Cleaned",
        icon: "Sparkles"
    },
    {
        value: "HAC6056",
        label: "Accessible Parking: Indoor",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6057",
        label: "Accessible Parking: Indoor, 1 spot",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6058",
        label: "Accessible Parking: Indoor, 2 spots",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6059",
        label: "Accessible Parking: Outdoor",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6060",
        label: "Accessible Parking: Outdoor, 1 spot",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6061",
        label: "Accessible Parking: Outdoor, 2 spots",
        icon: "ParkingSquare"
    },
    {
        value: "HAC6062",
        label: "Beach Access",
        icon: "PalmTree"
    },
    {
        value: "HAC6063",
        label: "Business Facilities",
        icon: "Briefcase"
    },
    {
        value: "HAC6064",
        label: "Courtyard: Shared",
        icon: "Users"
    },
    {
        value: "HAC6065",
        label: "Courtyard: Private Use",
        icon: "Garden"
    },
    {
        value: "HAC6066",
        label: "Fire Pit",
        icon: "Flame"
    },
    {
        value: "HAC6067",
        label: "Fitness Center: Access to community facilities",
        icon: "Dumbbell"
    },
    {
        value: "HAC6068",
        label: "Fitness Center: Private",
        icon: "Dumbbell"
    },
    {
        value: "HAC6069",
        label: "Heli-pad",
        icon: "PlaneTakeoff"
    },
    {
        value: "HAC6070",
        label: "Home Theatre System",
        icon: "Tv"
    },
    {
        value: "HAC6072",
        label: "Lakefront",
        icon: "Droplets"
    },
    {
        value: "HAC6073",
        label: "Laundry Facilities",
        icon: "Shirt"
    },
    {
        value: "HAC6074",
        label: "Outdoor Dining Area: Private",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6075",
        label: "Outdoor Dining Area: Shared",
        icon: "Utensils"
    },
    {
        value: "HAC6076",
        label: "Outdoor Dining Area: Screened-in",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6077",
        label: "Outdoor Dining Area: Seats 10",
        icon: "Armchair"
    },
    {
        value: "HAC6078",
        label: "Outdoor Dining Area: Seats 12",
        icon: "Armchair"
    },
    {
        value: "HAC6079",
        label: "Outdoor Dining Area: Seats 20+",
        icon: "Armchair"
    },
    {
        value: "HAC6080",
        label: "Outdoor Furniture Area",
        icon: "Sofa"
    },
    {
        value: "HAC6081",
        label: "Outdoor Furniture Area: Sun Loungers",
        icon: "Sofa"
    },
    {
        value: "HAC6082",
        label: "Outdoor Furniture Area: Dining Area",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6084",
        label: "Pool: Infinity Edge",
        icon: "Waves"
    },
    {
        value: "HAC6085",
        label: "Pool: Saltwater",
        icon: "Waves"
    },
    {
        value: "HAC6086",
        label: "Pool: Community Access",
        icon: "Users"
    },
    {
        value: "HAC6087",
        label: "Riverfront",
        icon: "Waves"
    },
    {
        value: "HAC6088",
        label: "Sauna: Private",
        icon: "Thermometer"
    },
    {
        value: "HAC6089",
        label: "Sauna: Shared",
        icon: "Thermometer"
    },
    {
        value: "HAC6090",
        label: "Sauna: En-suite",
        icon: "Thermometer"
    },
    {
        value: "HAC6091",
        label: "Seafront",
        icon: "Waves"
    },
    {
        value: "HAC6092",
        label: "Ski rental: Available for additional fee",
        icon: "Snowflake"
    },
    {
        value: "HAC6093",
        label: "Ski rental: Rental cost included with stay",
        icon: "Snowflake"
    },
    {
        value: "HAC6095",
        label: "Steam room",
        icon: "CloudFog"
    },
    {
        value: "HAC6096",
        label: "Bathroom Amenities: Robe included",
        icon: "Bath"
    },
    {
        value: "HAC6097",
        label: "Children's play area: Indoor",
        icon: "Baby"
    },
    {
        value: "HAC6098",
        label: "Children's play area: Outdoor",
        icon: "Baby"
    },
    {
        value: "HAC6099",
        label: "High Chair",
        icon: "Armchair"
    },
    {
        value: "HAC61",
        label: "Massage services",
        icon: "HandHelping"
    },
    {
        value: "HAC6100",
        label: "Table Tennis: Indoor",
        icon: "TableTennis"
    },
    {
        value: "HAC6101",
        label: "Table Tennis: Outdoor",
        icon: "TableTennis"
    },
    {
        value: "HAC6102",
        label: "Television: Plasma",
        icon: "Tv"
    },
    {
        value: "HAC6103",
        label: "Television: Flat screen",
        icon: "Tv"
    },
    {
        value: "HAC6104",
        label: "Television: Smart",
        icon: "TvMinimal"
    },
    {
        value: "HAC6105",
        label: "Water Sports: Available for rent",
        icon: "Waves"
    },
    {
        value: "HAC6106",
        label: "Water Sports: Available for additional fee",
        icon: "Waves"
    },
    {
        value: "HAC6107",
        label: "Baby Sitting: On request",
        icon: "Baby"
    },
    {
        value: "HAC6108",
        label: "Baby Sitting: Available for additional fee",
        icon: "Baby"
    },
    {
        value: "HAC6109",
        label: "Bartender",
        icon: "Wine"
    },
    {
        value: "HAC6110",
        label: "Bartender: On request",
        icon: "Wine"
    },
    {
        value: "HAC6111",
        label: "Bartender: Available for additional fee",
        icon: "Wine"
    },
    {
        value: "HAC6113",
        label: "Butler: On request",
        icon: "UserRound"
    },
    {
        value: "HAC6114",
        label: "Butler: Available for additional fee",
        icon: "UserRound"
    },
    {
        value: "HAC6115",
        label: "Chef Services: Included",
        icon: "ChefHat"
    },
    {
        value: "HAC6116",
        label: "Chef Services: Available for additional fee",
        icon: "ChefHat"
    },
    {
        value: "HAC6117",
        label: "Chef Services: Working schedule",
        icon: "ChefHat"
    },
    {
        value: "HAC6118",
        label: "Concierge Services: AM only",
        icon: "UserRound"
    },
    {
        value: "HAC6119",
        label: "Concierge Services: PM only",
        icon: "UserRound"
    },
    {
        value: "HAC6120",
        label: "Concierge Services: Weekends only",
        icon: "UserRound"
    },
    {
        value: "HAC6121",
        label: "Dry Cleaning: On request",
        icon: "WashingMachine"
    },
    {
        value: "HAC6122",
        label: "Dry Cleaning: Available for additional fee",
        icon: "WashingMachine"
    },
    {
        value: "HAC6123",
        label: "Gardener",
        icon: "Shrub"
    },
    {
        value: "HAC6124",
        label: "Grocery shopping service available: On request",
        icon: "ShoppingCart"
    },
    {
        value: "HAC6125",
        label: "Grocery shopping service available: Available for additional fee",
        icon: "ShoppingCart"
    },
    {
        value: "HAC6126",
        label: "Housekeeping: 2x Daily",
        icon: "Bed"
    },
    {
        value: "HAC6127",
        label: "Housekeeping: Additional Services",
        icon: "Bed"
    },
    {
        value: "HAC6128",
        label: "Housekeeping: Additional services available on request",
        icon: "Bed"
    },
    {
        value: "HAC6129",
        label: "Housekeeping: Additional services available for extra fee",
        icon: "Bed"
    },
    {
        value: "HAC6130",
        label: "House Manager",
        icon: "UserRound"
    },
    {
        value: "HAC6131",
        label: "In-person Welcome",
        icon: "HandshakeCheck"
    },
    {
        value: "HAC6132",
        label: "Laundry Services",
        icon: "WashingMachine"
    },
    {
        value: "HAC6133",
        label: "Laundry Services: Availabe for additional fee",
        icon: "WashingMachine"
    },
    {
        value: "HAC6134",
        label: "Meal Included: Breakfast",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6135",
        label: "Meal Included: 3 meals/ day",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6136",
        label: "Meal Included: Available for additional fee",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC6137",
        label: "Valet Parking: On request",
        icon: "Car"
    },
    {
        value: "HAC6138",
        label: "Valet Parking: Available for additional fee",
        icon: "Car"
    },
    {
        value: "HAC62",
        label: "Nightclub",
        icon: "Music"
    },
    {
        value: "HAC66",
        label: "Outdoor pool",
        icon: "Waves"
    },
    {
        value: "HAC7",
        label: "ATM/Cash machine",
        icon: "Banknote"
    },
    {
        value: "HAC71",
        label: "Pool",
        icon: "Waves"
    },
    {
        value: "HAC76",
        label: "Restaurant",
        icon: "UtensilsCrossed"
    },
    {
        value: "HAC77",
        label: "Room service",
        icon: "BellRing"
    },
    {
        value: "HAC78",
        label: "Safe deposit box",
        icon: "Lock"
    },
    {
        value: "HAC79",
        label: "Sauna",
        icon: "Thermometer"
    },
    {
        value: "HAC8",
        label: "Baby sitting",
        icon: "Baby"
    },
    {
        value: "HAC81",
        label: "Shoe shine stand",
        icon: "Brush"
    },
    {
        value: "HAC83",
        label: "Solarium",
        icon: "Sun"
    },
    {
        value: "HAC86",
        label: "Steam bath",
        icon: "CloudFog"
    },
    {
        value: "HAC9",
        label: "BBQ/Picnic area",
        icon: "Flame"
    },
    {
        value: "HAC91",
        label: "Tour/sightseeing desk",
        icon: "Landmark"
    },
    {
        value: "HAC94",
        label: "Truck parking",
        icon: "Truck"
    },
    {
        value: "HAC96",
        label: "Dry cleaning",
        icon: "WashingMachine"
    },
    {
        value: "HAC97",
        label: "Valet parking",
        icon: "Car"
    },
    {
        value: "HAC98",
        label: "Vending machines",
        icon: "Store"
    },
    {
        value: "RMA1",
        label: "Adjoining rooms",
        icon: "House"
    },
    {
        value: "RMA10",
        label: "Bathrobe",
        icon: "Clothing"
    },
    {
        value: "RMA100",
        label: "Smoke detectors",
        icon: "Smoke"
    },
    {
        value: "RMA102",
        label: "Sofa bed",
        icon: "Sofa"
    },
    {
        value: "RMA103",
        label: "Speaker phone",
        icon: "Phone"
    },
    {
        value: "RMA104",
        label: "Stereo",
        icon: "Music"
    },
    {
        value: "RMA105",
        label: "Stove",
        icon: "Cooking"
    },
    {
        value: "RMA107",
        label: "Telephone",
        icon: "Phone"
    },
    {
        value: "RMA108",
        label: "Telephone for hearing impaired",
        icon: "Phone"
    },
    {
        value: "RMA11",
        label: "Bathroom amenities",
        icon: "Bath"
    },
    {
        value: "RMA111",
        label: "Trouser/Pant press",
        icon: "Clothing"
    },
    {
        value: "RMA113",
        label: "Twin bed",
        icon: "Bed"
    },
    {
        value: "RMA115",
        label: "VCR movies",
        icon: "Tv"
    },
    {
        value: "RMA117",
        label: "Video games",
        icon: "Game"
    },
    {
        value: "RMA119",
        label: "Wake-up calls",
        icon: "AlarmClock"
    },
    {
        value: "RMA126",
        label: "Air conditioning individually controlled in room",
        icon: "AirConditioning"
    },
    {
        value: "RMA127",
        label: "Bathtub &whirlpool separate",
        icon: "Bath"
    },
    {
        value: "RMA129",
        label: "CD  player",
        icon: "Tv"
    },
    {
        value: "RMA13",
        label: "Bathtub",
        icon: "Bath"
    },
    {
        value: "RMA133",
        label: "Desk with electrical outlet",
        icon: "Desk"
    },
    {
        value: "RMA135",
        label: "Foam pillows",
        icon: "Bed"
    },
    {
        value: "RMA138",
        label: "Marble bathroom",
        icon: "Bath"
    },
    {
        value: "RMA139",
        label: "List of movie channels available",
        icon: "Tv"
    },
    {
        value: "RMA14",
        label: "Bathtub only",
        icon: "Bath"
    },
    {
        value: "RMA141",
        label: "Oversized bathtub",
        icon: "Bath"
    },
    {
        value: "RMA142",
        label: "Shower",
        icon: "Shower"
    },
    {
        value: "RMA143",
        label: "Sink in-room",
        icon: "Sink"
    },
    {
        value: "RMA144",
        label: "Soundproofed room",
        icon: "Soundproofing"
    },
    {
        value: "RMA146",
        label: "Tables and chairs",
        icon: "Table"
    },
    {
        value: "RMA147",
        label: "Two-line phone",
        icon: "Phone"
    },
    {
        value: "RMA149",
        label: "Washer/dryer",
        icon: "WashingMachine"
    },
    {
        value: "RMA15",
        label: "Bathtub or Shower",
        icon: "Bath"
    },
    {
        value: "RMA151",
        label: "Welcome gift",
        icon: "Gift"
    },
    {
        value: "RMA155",
        label: "Separate tub and shower",
        icon: "Bath"
    },
    {
        value: "RMA157",
        label: "Ceiling fan",
        icon: "Fan"
    },
    {
        value: "RMA158",
        label: "CNN available",
        icon: "Tv"
    },
    {
        value: "RMA16",
        label: "Bidet",
        icon: "Bath"
    },
    {
        value: "RMA161",
        label: "Accessible room",
        icon: "Wheelchair"
    },
    {
        value: "RMA162",
        label: "Closets in room",
        icon: "Closet"
    },
    {
        value: "RMA163",
        label: "DVD player",
        icon: "Tv"
    },
    {
        value: "RMA164",
        label: "Mini-refrigerator",
        icon: "Refrigerator"
    },
    {
        value: "RMA166",
        label: "Heating",
        icon: "Heating"
    },
    {
        value: "RMA167",
        label: "Toaster",
        icon: "Toaster"
    },
    {
        value: "RMA170",
        label: "International calls",
        icon: "Phone"
    },
    {
        value: "RMA175",
        label: "Long distance calls",
        icon: "Phone"
    },
    {
        value: "RMA18",
        label: "Cable television",
        icon: "Tv"
    },
    {
        value: "RMA186",
        label: "Hypoallergenic bed",
        icon: "Bed"
    },
    {
        value: "RMA19",
        label: "Coffee/Tea maker",
        icon: "Coffee"
    },
    {
        value: "RMA190",
        label: "Meal included - continental breakfast",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA191",
        label: "Meal included - dinner",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA192",
        label: "Meal included - lunch",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA193",
        label: "Shared bathroom",
        icon: "Bath"
    },
    {
        value: "RMA194",
        label: "Telephone TDD/Textphone",
        icon: "Phone"
    },
    {
        value: "RMA195",
        label: "Water bed",
        icon: "Bed"
    },
    {
        value: "RMA2",
        label: "Air conditioning",
        icon: "AirConditioning"
    },
    {
        value: "RMA20",
        label: "Color television",
        icon: "Tv"
    },
    {
        value: "RMA200",
        label: "Futon",
        icon: "Bed"
    },
    {
        value: "RMA201",
        label: "Murphy bed",
        icon: "Bed"
    },
    {
        value: "RMA203",
        label: "Single bed",
        icon: "Bed"
    },
    {
        value: "RMA204",
        label: "Annex room",
        icon: "House"
    },
    {
        value: "RMA205",
        label: "Free newspaper",
        icon: "Newspaper"
    },
    {
        value: "RMA207",
        label: "Complimentary high speed internet in room",
        icon: "Internet"
    },
    {
        value: "RMA21",
        label: "Computer",
        icon: "Computer"
    },
    {
        value: "RMA210",
        label: "Satellite television",
        icon: "Tv"
    },
    {
        value: "RMA214",
        label: "iPod docking station",
        icon: "Tv"
    },
    {
        value: "RMA217",
        label: "Satellite radio",
        icon: "Radio"
    },
    {
        value: "RMA218",
        label: "Video on demand",
        icon: "Tv"
    },
    {
        value: "RMA22",
        label: "Connecting rooms",
        icon: "House"
    },
    {
        value: "RMA220",
        label: "Gulf view",
        icon: "Waves"
    },
    {
        value: "RMA223",
        label: "Mountain view",
        icon: "Mountain"
    },
    {
        value: "RMA224",
        label: "Ocean view",
        icon: "Waves"
    },
    {
        value: "RMA227",
        label: "Premium movie channels",
        icon: "Tv"
    },
    {
        value: "RMA228",
        label: "Slippers",
        icon: "Clothing"
    },
    {
        value: "RMA230",
        label: "Chair provided with desk",
        icon: "Desk"
    },
    {
        value: "RMA231",
        label: "Pillow top mattress",
        icon: "Bed"
    },
    {
        value: "RMA234",
        label: "Luxury linen type",
        icon: "Clothing"
    },
    {
        value: "RMA242",
        label: "Instant hot water",
        icon: "HotWater"
    },
    {
        value: "RMA245",
        label: "RMA245",
        icon: "RMA245"
    },
    {
        value: "RMA246",
        label: "High Definition (HD) Flat Panel Television - 32 inches or greater",
        icon: "Tv"
    },
    {
        value: "RMA25",
        label: "Cordless phone",
        icon: "Phone"
    },
    {
        value: "RMA251",
        label: "TV",
        icon: "Tv"
    },
    {
        value: "RMA254",
        label: "Video game player:",
        icon: "Game"
    },
    {
        value: "RMA256",
        label: "Dining room seats",
        icon: "Table"
    },
    {
        value: "RMA258",
        label: "Mobile/cellular phones",
        icon: "Phone"
    },
    {
        value: "RMA259",
        label: "Movies",
        icon: "Tv"
    },
    {
        value: "RMA26",
        label: "Cribs",
        icon: "Baby"
    },
    {
        value: "RMA260",
        label: "Multiple closets",
        icon: "Closet"
    },
    {
        value: "RMA262",
        label: "Safe large enough to accommodate a laptop",
        icon: "Lock"
    },
    {
        value: "RMA265",
        label: "Bluray player",
        icon: "Tv"
    },
    {
        value: "RMA268",
        label: "Non-allergenic room",
        icon: "Allergies"
    },
    {
        value: "RMA269",
        label: "Pillow type",
        icon: "Bed"
    },
    {
        value: "RMA270",
        label: "Seating area with sofa/chair",
        icon: "Sofa"
    },
    {
        value: "RMA271",
        label: "Separate toilet area",
        icon: "Bath"
    },
    {
        value: "RMA273",
        label: "Widescreen TV",
        icon: "Tv"
    },
    {
        value: "RMA276",
        label: "Separate tub or shower",
        icon: "Bath"
    },
    {
        value: "RMA279",
        label: "Children's playpen",
        icon: "Baby"
    },
    {
        value: "RMA28",
        label: "Desk",
        icon: "Desk"
    },
    {
        value: "RMA280",
        label: "Plunge pool",
        icon: "Waves"
    },
    {
        value: "RMA29",
        label: "Desk with lamp",
        icon: "Desk"
    },
    {
        value: "RMA3",
        label: "Alarm clock",
        icon: "AlarmClock"
    },
    {
        value: "RMA32",
        label: "Dishwasher",
        icon: "Cooking"
    },
    {
        value: "RMA33",
        label: "Double bed",
        icon: "Bed"
    },
    {
        value: "RMA38",
        label: "Fax machine",
        icon: "Phone"
    },
    {
        value: "RMA41",
        label: "Fireplace",
        icon: "Fireplace"
    },
    {
        value: "RMA45",
        label: "Free local calls",
        icon: "Phone"
    },
    {
        value: "RMA46",
        label: "Free movies/video",
        icon: "Tv"
    },
    {
        value: "RMA47",
        label: "Full kitchen",
        icon: "Cooking"
    },
    {
        value: "RMA49",
        label: "Grecian tub",
        icon: "Bath"
    },
    {
        value: "RMA5",
        label: "AM/FM radio",
        icon: "Radio"
    },
    {
        value: "RMA50",
        label: "Hairdryer",
        icon: "Hairdryer"
    },
    {
        value: "RMA5005",
        label: "Bath",
        icon: "Bath"
    },
    {
        value: "RMA5020",
        label: "Spa Bath",
        icon: "Bath"
    },
    {
        value: "RMA5085",
        label: "Dining Area",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA5086",
        label: "Electric Kettle",
        icon: "Cooking"
    },
    {
        value: "RMA5091",
        label: "Towels/Linens At Surcharge",
        icon: "Clothing"
    },
    {
        value: "RMA5126",
        label: "Dining Table",
        icon: "Table"
    },
    {
        value: "RMA5145",
        label: "Baby safety gates",
        icon: "Baby"
    },
    {
        value: "RMA55",
        label: "Iron",
        icon: "Iron"
    },
    {
        value: "RMA56",
        label: "Ironing board",
        icon: "IroningBoard"
    },
    {
        value: "RMA57",
        label: "Whirpool",
        icon: "Bath"
    },
    {
        value: "RMA58",
        label: "King bed",
        icon: "Bed"
    },
    {
        value: "RMA59",
        label: "Kitchen",
        icon: "Cooking"
    },
    {
        value: "RMA6",
        label: "Baby listening device",
        icon: "Baby"
    },
    {
        value: "RMA60",
        label: "Kitchen supplies",
        icon: "Cooking"
    },
    {
        value: "RMA6001",
        label: "Towels available",
        icon: "Clothing"
    },
    {
        value: "RMA6003",
        label: "Cats allowed",
        icon: "Cat"
    },
    {
        value: "RMA6004",
        label: "Dogs allowed",
        icon: "Dog"
    },
    {
        value: "RMA6005",
        label: "CD library",
        icon: "Tv"
    },
    {
        value: "RMA6010",
        label: "Separate entrance",
        icon: "Door"
    },
    {
        value: "RMA6012",
        label: "Beach view",
        icon: "Waves"
    },
    {
        value: "RMA6013",
        label: "Pool view",
        icon: "Waves"
    },
    {
        value: "RMA6017",
        label: "Water view",
        icon: "Waves"
    },
    {
        value: "RMA6018",
        label: "Electronic room key",
        icon: "Key"
    },
    {
        value: "RMA6021",
        label: "Fire extinguishers",
        icon: "FireExtinguisher"
    },
    {
        value: "RMA6027",
        label: "Golf view",
        icon: "Golf"
    },
    {
        value: "RMA6028",
        label: "Ground floor",
        icon: "Floor"
    },
    {
        value: "RMA6029",
        label: "Lake view",
        icon: "Waves"
    },
    {
        value: "RMA6030",
        label: "City view",
        icon: "City"
    },
    {
        value: "RMA6031",
        label: "Harbor view",
        icon: "Waves"
    },
    {
        value: "RMA6032",
        label: "Bunk bed",
        icon: "Bed"
    },
    {
        value: "RMA6033",
        label: "Washer",
        icon: "WashingMachine"
    },
    {
        value: "RMA6034",
        label: "Dryer",
        icon: "WashingMachine"
    },
    {
        value: "RMA6035",
        label: "Hangers",
        icon: "Clothing"
    },
    {
        value: "RMA6036",
        label: "Laptop Friendly Workspace",
        icon: "Computer"
    },
    {
        value: "RMA6037",
        label: "Essentials",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6038",
        label: "Extra bed",
        icon: "Bed"
    },
    {
        value: "RMA6039",
        label: "Patio",
        icon: "Patio"
    },
    {
        value: "RMA6040",
        label: "iPad",
        icon: "Tablet"
    },
    {
        value: "RMA6042",
        label: "Living room",
        icon: "LivingRoom"
    },
    {
        value: "RMA6045",
        label: "Outdoor furniture",
        icon: "Sofa"
    },
    {
        value: "RMA6046",
        label: "Outdoor dinning area",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6051",
        label: "Garden view",
        icon: "Garden"
    },
    {
        value: "RMA6052",
        label: "River view",
        icon: "Waves"
    },
    {
        value: "RMA6053",
        label: "Park view",
        icon: "Park"
    },
    {
        value: "RMA6054",
        label: "Game Console - Xbox 360",
        icon: "Game"
    },
    {
        value: "RMA6055",
        label: "Game Console - PS2",
        icon: "Game"
    },
    {
        value: "RMA6056",
        label: "Game Console - PS3",
        icon: "Game"
    },
    {
        value: "RMA6057",
        label: "Game Console - Nintendo Wii",
        icon: "Game"
    },
    {
        value: "RMA6058",
        label: "Linen",
        icon: "Clothing"
    },
    {
        value: "RMA6059",
        label: "Carpeted floor",
        icon: "Floor"
    },
    {
        value: "RMA6060",
        label: "Tiled floor",
        icon: "Floor"
    },
    {
        value: "RMA6061",
        label: "Parquet Floor",
        icon: "Floor"
    },
    {
        value: "RMA6062",
        label: "Upper Floor Reachable By Stairs Only",
        icon: "Stairs"
    },
    {
        value: "RMA6063",
        label: "Semi-Detached",
        icon: "House"
    },
    {
        value: "RMA6064",
        label: "Detached",
        icon: "House"
    },
    {
        value: "RMA6065",
        label: "Mosquito Net",
        icon: "MosquitoNet"
    },
    {
        value: "RMA6066",
        label: "Cleaning Included",
        icon: "WashingMachine"
    },
    {
        value: "RMA6067",
        label: "Carbon monoxide detector",
        icon: "Smoke"
    },
    {
        value: "RMA6068",
        label: "First aid kit",
        icon: "FirstAid"
    },
    {
        value: "RMA6071",
        label: "Buzzer/wireless intercom",
        icon: "Phone"
    },
    {
        value: "RMA6072",
        label: "Safety card",
        icon: "Safety"
    },
    {
        value: "RMA6073",
        label: "Lock on bedroom door",
        icon: "Lock"
    },
    {
        value: "RMA6074",
        label: "Smart lock",
        icon: "Lock"
    },
    {
        value: "RMA6075",
        label: "Private entrance",
        icon: "Door"
    },
    {
        value: "RMA6076",
        label: "Outlet covers",
        icon: "Outlet"
    },
    {
        value: "RMA6077",
        label: "Baby bath",
        icon: "Bath"
    },
    {
        value: "RMA6078",
        label: "Stair gates",
        icon: "Stairs"
    },
    {
        value: "RMA6079",
        label: "Window guards",
        icon: "Window"
    },
    {
        value: "RMA6080",
        label: "Table corner guards",
        icon: "Table"
    },
    {
        value: "RMA6081",
        label: "Fireplace guards",
        icon: "Fireplace"
    },
    {
        value: "RMA6082",
        label: "Room darkening shades",
        icon: "Shades"
    },
    {
        value: "RMA6083",
        label: "Childrens dinnerware",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6084",
        label: "Children's books and toys",
        icon: "Book"
    },
    {
        value: "RMA6085",
        label: "Breakfast not available",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6086",
        label: "Dinner booking possible",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6087",
        label: "Dinner not available",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6088",
        label: "House cleaning optional",
        icon: "WashingMachine"
    },
    {
        value: "RMA6089",
        label: "Lunch booking possible",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6090",
        label: "Lunch not available",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6091",
        label: "Wood stove",
        icon: "Fireplace"
    },
    {
        value: "RMA6092",
        label: "Dining Highchair",
        icon: "Armchair"
    },
    {
        value: "RMA6093",
        label: "Dining Raclette",
        icon: "Cooking"
    },
    {
        value: "RMA6094",
        label: "Dining room",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6095",
        label: "Dining spices",
        icon: "Cooking"
    },
    {
        value: "RMA6096",
        label: "Meals guests furnish own",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA6097",
        label: "Limited accessibility",
        icon: "Wheelchair"
    },
    {
        value: "RMA6098",
        label: "Wheelchair inaccessible",
        icon: "Wheelchair"
    },
    {
        value: "RMA6099",
        label: "Breakfast booking possible",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA61",
        label: "Kitchenette",
        icon: "Cooking"
    },
    {
        value: "RMA6100",
        label: "Extra pillows and blankets",
        icon: "Clothing"
    },
    {
        value: "RMA6101",
        label: "Ethernet connection",
        icon: "Internet"
    },
    {
        value: "RMA6102",
        label: "Pocket wifi",
        icon: "Internet"
    },
    {
        value: "RMA6103",
        label: "Cooking basics",
        icon: "Cooking"
    },
    {
        value: "RMA6104",
        label: "Changing table",
        icon: "Baby"
    },
    {
        value: "RMA6105",
        label: "Bedroom step free access",
        icon: "Wheelchair"
    },
    {
        value: "RMA6106",
        label: "Wide clearance to bed",
        icon: "Wheelchair"
    },
    {
        value: "RMA6107",
        label: "Bedroom wide doorway",
        icon: "Door"
    },
    {
        value: "RMA6108",
        label: "Accessible height bed",
        icon: "Wheelchair"
    },
    {
        value: "RMA6109",
        label: "Bathroom step free access",
        icon: "Wheelchair"
    },
    {
        value: "RMA6110",
        label: "Grab rails in shower and toilet",
        icon: "Handrail"
    },
    {
        value: "RMA6111",
        label: "Accessible height toilet",
        icon: "Toilet"
    },
    {
        value: "RMA6112",
        label: "Rollin shower with bench",
        icon: "Shower"
    },
    {
        value: "RMA6113",
        label: "Bathroom wide doorway",
        icon: "Door"
    },
    {
        value: "RMA6114",
        label: "Tub with shower bench",
        icon: "Bath"
    },
    {
        value: "RMA6115",
        label: "Wide clearance to shower and toilet",
        icon: "Shower"
    },
    {
        value: "RMA6116",
        label: "Handheld shower hear",
        icon: "Shower"
    },
    {
        value: "RMA6117",
        label: "Single level home",
        icon: "House"
    },
    {
        value: "RMA6118",
        label: "Couch",
        icon: "Sofa"
    },
    {
        value: "RMA6119",
        label: "Air mattress",
        icon: "Bed"
    },
    {
        value: "RMA6120",
        label: "Floor mattress",
        icon: "Bed"
    },
    {
        value: "RMA6121",
        label: "Toddler bed",
        icon: "Bed"
    },
    {
        value: "RMA6122",
        label: "Hammock",
        icon: "Hammock"
    },
    {
        value: "RMA6123",
        label: "Freezer",
        icon: "Refrigerator"
    },
    {
        value: "RMA6124",
        label: "Private yard",
        icon: "Garden"
    },
    {
        value: "RMA6125",
        label: "Ensuite",
        icon: "Bath"
    },
    {
        value: "RMA6126",
        label: "High-Speed Wifi",
        icon: "Internet"
    },
    {
        value: "RMA6127",
        label: "Patio/Balcony",
        icon: "Patio"
    },
    {
        value: "RMA6128",
        label: "Balcony: Garden View",
        icon: "Patio"
    },
    {
        value: "RMA6129",
        label: "Balcony: City View",
        icon: "City"
    },
    {
        value: "RMA6130",
        label: "Balcony: Ocean View",
        icon: "Waves"
    },
    {
        value: "RMA6131",
        label: "Balcony: Mountain View",
        icon: "Mountain"
    },
    {
        value: "RMA6132",
        label: "Fireplace: Wood burning",
        icon: "Fireplace"
    },
    {
        value: "RMA6133",
        label: "Fireplace: Gas",
        icon: "Fireplace"
    },
    {
        value: "RMA6134",
        label: "Fireplace: Electric",
        icon: "Fireplace"
    },
    {
        value: "RMA6135",
        label: "Pizza Oven",
        icon: "Cooking"
    },
    {
        value: "RMA6136",
        label: "Air Conditioning: Central",
        icon: "AirConditioning"
    },
    {
        value: "RMA6137",
        label: "Air Conditioning: Partial",
        icon: "AirConditioning"
    },
    {
        value: "RMA6138",
        label: "RMA6138 Air Conditioning: In rooms",
        icon: "AirConditioning"
    },
    {
        value: "RMA6139",
        label: "Baby Crib",
        icon: "Baby"
    },
    {
        value: "RMA6140",
        label: "Barbeque Grill: Gas",
        icon: "Cooking"
    },
    {
        value: "RMA6141",
        label: "Barbeque Grill: Propane",
        icon: "Cooking"
    },
    {
        value: "RMA6142",
        label: "Barbeque Grill: Charcoal",
        icon: "Cooking"
    },
    {
        value: "RMA6144",
        label: "Premium Linens & Towels",
        icon: "Clothing"
    },
    {
        value: "RMA6145",
        label: "Kitchen Essentials",
        icon: "Cooking"
    },
    {
        value: "RMA6146",
        label: "In-unit Washer",
        icon: "WashingMachine"
    },
    {
        value: "RMA6147",
        label: "Self-controlled heating/cooling system",
        icon: "AirConditioning"
    },
    {
        value: "RMA6148",
        label: "Private living room",
        icon: "LivingRoom"
    },
    {
        value: "RMA6149",
        label: "Amenity toilet",
        icon: "Toilet"
    },
    {
        value: "RMA6150",
        label: "Amenity combo tub shower",
        icon: "Bath"
    },
    {
        value: "RMA6151",
        label: "Amenity outdoor shower",
        icon: "Shower"
    },
    {
        value: "RMA6154",
        label: "Entertainment Books",
        icon: "Book"
    },
    {
        value: "RMA6155",
        label: "Entertainment Toys",
        icon: "Toy"
    },
    {
        value: "RMA6156",
        label: "Security System",
        icon: "Security"
    },
    {
        value: "RMA6157",
        label: "Private Dock",
        icon: "Dock"
    },
    {
        value: "RMA6158",
        label: "Indoor Fireplace",
        icon: "Fireplace"
    },
    {
        value: "RMA63",
        label: "Laptop",
        icon: "Computer"
    },
    {
        value: "RMA64",
        label: "Large desk",
        icon: "Desk"
    },
    {
        value: "RMA67",
        label: "Loft",
        icon: "House"
    },
    {
        value: "RMA68",
        label: "Microwave",
        icon: "Cooking"
    },
    {
        value: "RMA69",
        label: "Minibar",
        icon: "Refrigerator"
    },
    {
        value: "RMA7",
        label: "Balcony/Lanai/Terrace",
        icon: "Patio"
    },
    {
        value: "RMA72",
        label: "Multi-line phone",
        icon: "Phone"
    },
    {
        value: "RMA77",
        label: "Oven",
        icon: "Cooking"
    },
    {
        value: "RMA78",
        label: "Pay per view movies on TV",
        icon: "Tv"
    },
    {
        value: "RMA8",
        label: "Barbeque grills",
        icon: "Flame"
    },
    {
        value: "RMA80",
        label: "Phone in bathroom",
        icon: "Phone"
    },
    {
        value: "RMA81",
        label: "Plates and bowls",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA85",
        label: "Private bathroom",
        icon: "Bath"
    },
    {
        value: "RMA86",
        label: "Queen bed",
        icon: "Bed"
    },
    {
        value: "RMA88",
        label: "Refrigerator",
        icon: "Refrigerator"
    },
    {
        value: "RMA89",
        label: "Refrigerator with ice maker",
        icon: "Refrigerator"
    },
    {
        value: "RMA9",
        label: "Bath tub with spray jets",
        icon: "Bath"
    },
    {
        value: "RMA91",
        label: "Rollaway bed",
        icon: "Bed"
    },
    {
        value: "RMA92",
        label: "Safe",
        icon: "Lock"
    },
    {
        value: "RMA94",
        label: "Separate closet",
        icon: "Closet"
    },
    {
        value: "RMA97",
        label: "Shower only",
        icon: "Shower"
    },
    {
        value: "RMA98",
        label: "Silverware/utensils",
        icon: "UtensilsCrossed"
    },
    {
        value: "RMA99",
        label: "Sitting area",
        icon: "Sofa"
    },
    {
        value: "RST101",
        label: "Windsurfing",
        icon: "Windsurfing"
    },
    {
        value: "RST104",
        label: "Hot tub",
        icon: "HotTub"
    },
    {
        value: "RST105",
        label: "Hunting",
        icon: "Hunting"
    },
    {
        value: "RST108",
        label: "Mountain climbing",
        icon: "MountainClimbing"
    },
    {
        value: "RST111",
        label: "Billiards",
        icon: "Billiards"
    },
    {
        value: "RST112",
        label: "Rock climbing",
        icon: "RockClimbing"
    },
    {
        value: "RST116",
        label: "Surfing",
        icon: "Surfing"
    },
    {
        value: "RST117",
        label: "Table tennis",
        icon: "TableTennis"
    },
    {
        value: "RST120",
        label: "Snow mobiling",
        icon: "Snowmobiling"
    },
    {
        value: "RST123",
        label: "Outdoor pool",
        icon: "Waves"
    },
    {
        value: "RST127",
        label: "Bird watching",
        icon: "BirdWatching"
    },
    {
        value: "RST129",
        label: "Children's pool",
        icon: "Pool"
    },
    {
        value: "RST13",
        label: "Cross country skiing",
        icon: "CrossCountrySkiing"
    },
    {
        value: "RST133",
        label: "Gambling",
        icon: "Gambling"
    },
    {
        value: "RST134",
        label: "Garden",
        icon: "Garden"
    },
    {
        value: "RST137",
        label: "Ice skating",
        icon: "IceSkating"
    },
    {
        value: "RST14",
        label: "Dart board",
        icon: "Dart"
    },
    {
        value: "RST149",
        label: "Canoeing",
        icon: "Canoeing"
    },
    {
        value: "RST151",
        label: "Outlet shopping",
        icon: "Shopping"
    },
    {
        value: "RST156",
        label: "Ski in/out facilities",
        icon: "Ski"
    },
    {
        value: "RST157",
        label: "Tennis professional",
        icon: "Tennis"
    },
    {
        value: "RST160",
        label: "Diving",
        icon: "Diving"
    },
    {
        value: "RST161",
        label: "Walking track",
        icon: "Walking"
    },
    {
        value: "RST169",
        label: "Bikes Available (Free)",
        icon: "Bicycle"
    },
    {
        value: "RST170",
        label: "Away from it all",
        icon: "Mountain"
    },
    {
        value: "RST171",
        label: "Budget",
        icon: "WalletCards"
    },
    {
        value: "RST172",
        label: "Farm holidays",
        icon: "Farm"
    },
    {
        value: "RST173",
        label: "Historic",
        icon: "Historic"
    },
    {
        value: "RST174",
        label: "Holiday complex",
        icon: "Holiday"
    },
    {
        value: "RST175",
        label: "Romantic",
        icon: "Romantic"
    },
    {
        value: "RST176",
        label: "Tourist attractions",
        icon: "TouristAttraction"
    },
    {
        value: "RST177",
        label: "Eco tourism",
        icon: "EcoTourism"
    },
    {
        value: "RST178",
        label: "Luaus",
        icon: "Luau"
    },
    {
        value: "RST179",
        label: "Paddle boating",
        icon: "PaddleBoating"
    },
    {
        value: "RST180",
        label: "Sledding",
        icon: "Sledding"
    },
    {
        value: "RST181",
        label: "Thermalisme",
        icon: "Thermalisme"
    },
    {
        value: "RST182",
        label: "Whale watching",
        icon: "WhaleWatching"
    },
    {
        value: "RST183",
        label: "Hot air ballooning",
        icon: "HotAirBallooning"
    },
    {
        value: "RST184",
        label: "Shelling",
        icon: "Shelling"
    },
    {
        value: "RST185",
        label: "Adventure",
        icon: "Adventure"
    },
    {
        value: "RST186",
        label: "Rural countryside retreats",
        icon: "Rural"
    },
    {
        value: "RST187",
        label: "Trampoline",
        icon: "Trampoline"
    },
    {
        value: "RST20",
        label: "Fishing",
        icon: "Fishing"
    },
    {
        value: "RST25",
        label: "Fly fishing",
        icon: "FlyFishing"
    },
    {
        value: "RST34",
        label: "Golf",
        icon: "Golf"
    },
    {
        value: "RST35",
        label: "Gym",
        icon: "Gym"
    },
    {
        value: "RST4",
        label: "Basketball court",
        icon: "Basketball"
    },
    {
        value: "RST5028",
        label: "Ski-to-door access",
        icon: "Ski"
    },
    {
        value: "RST60",
        label: "Hiking trail",
        icon: "Hiking"
    },
    {
        value: "RST63",
        label: "Jet-ski",
        icon: "JetSki"
    },
    {
        value: "RST65",
        label: "Kayaking",
        icon: "Kayaking"
    },
    {
        value: "RST67",
        label: "Miniature golf",
        icon: "MiniatureGolf"
    },
    {
        value: "RST68",
        label: "Mountain biking trail",
        icon: "MountainBiking"
    },
    {
        value: "RST7",
        label: "Boating",
        icon: "Boating"
    },
    {
        value: "RST71",
        label: "Outdoor tennis courts",
        icon: "Tennis"
    },
    {
        value: "RST73",
        label: "Parasailing",
        icon: "Parasailing"
    },
    {
        value: "RST74",
        label: "Playground",
        icon: "Playground"
    },
    {
        value: "RST79",
        label: "River rafting",
        icon: "RiverRafting"
    },
    {
        value: "RST80",
        label: "Sailing",
        icon: "Sailing"
    },
    {
        value: "RST82",
        label: "Scuba diving",
        icon: "ScubaDiving"
    },
    {
        value: "RST86",
        label: "Snorkeling",
        icon: "Snorkeling"
    },
    {
        value: "RST87",
        label: "Snow boarding",
        icon: "Snowboarding"
    },
    {
        value: "RST9",
        label: "Bowling alley",
        icon: "Bowling"
    },
    {
        value: "RST92",
        label: "Squash court",
        icon: "Squash"
    },
    {
        value: "RST96",
        label: "Tubing",
        icon: "Tubing"
    }
];
