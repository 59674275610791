"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "removeExtraSpace", {
    enumerable: true,
    get: function() {
        return removeExtraSpace;
    }
});
function removeExtraSpace(str) {
    return str.replace(/\s+/g, " ").trim();
}
