"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    formatNights: function() {
        return formatNights;
    },
    formatNumberOfGuests: function() {
        return formatNumberOfGuests;
    },
    formatNumberOfPets: function() {
        return formatNumberOfPets;
    }
});
function formatNumberOfGuests(numberOfGuests, upperFirstLetter = false) {
    const key = upperFirstLetter ? "Guest" : "guest";
    const numberOfGuestsFormatted = typeof numberOfGuests === "number" ? `${numberOfGuests > 1 ? `${numberOfGuests} ${key}s` : `${numberOfGuests} ${key}`}` : "#";
    return numberOfGuestsFormatted;
}
function formatNumberOfPets(numberOfPets, upperFirstLetter = false) {
    const key = upperFirstLetter ? "Pet" : "pet";
    const numberOfPetsFormatted = typeof numberOfPets === "number" ? `${numberOfPets > 1 ? `${numberOfPets} ${key}s` : `${numberOfPets} ${key}`}` : "#";
    return numberOfPetsFormatted;
}
function formatNights(nights, upperFirstLetter = false) {
    const key = upperFirstLetter ? "Night" : "night";
    const nightsFormatted = typeof nights === "number" ? `${nights > 1 ? `${nights} ${key}s` : `${nights} ${key}`}` : "#";
    return nightsFormatted;
}
