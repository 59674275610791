"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ITEM_PER_PAGE: function() {
        return ITEM_PER_PAGE;
    },
    MAX_ITEM_HEIGHT: function() {
        return MAX_ITEM_HEIGHT;
    }
});
const MAX_ITEM_HEIGHT = 70;
const ITEM_PER_PAGE = 20;
