"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _reactnative = require("react-native");
const _useColors = require("./useColors");
const useSelectStyles = ()=>{
    const colors = (0, _useColors.useColors)();
    const color = colors.blueGray["500"];
    const borderColor = colors.blueGray["200"];
    const styles = _reactnative.StyleSheet.create({
        inputIOS: {
            color,
            borderColor,
            paddingVertical: 10,
            paddingHorizontal: 8,
            borderWidth: 1,
            borderRadius: 4,
            paddingRight: 30 // to ensure the text is never behind the icon
        },
        inputAndroid: {
            color,
            borderColor,
            paddingVertical: 10,
            paddingHorizontal: 8,
            borderWidth: 0.5,
            borderRadius: 4,
            paddingRight: 30 // to ensure the text is never behind the icon
        }
    });
    return styles;
};
const _default = useSelectStyles;
