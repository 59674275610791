"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useCloseConfirmModal", {
    enumerable: true,
    get: function() {
        return useCloseConfirmModal;
    }
});
const _react = require("react");
const _AppProvider = require("../../providers/AppProvider");
const useCloseConfirmModal = (callback)=>{
    const callbackRef = (0, _react.useRef)(callback);
    callbackRef.current = callback;
    const { setConfirmModal } = (0, _react.useContext)(_AppProvider.AppContext);
    return (0, _react.useCallback)(()=>{
        var _callbackRef_current;
        setConfirmModal(undefined);
        (_callbackRef_current = callbackRef.current) === null || _callbackRef_current === void 0 ? void 0 : _callbackRef_current.call(callbackRef);
    }, [
        setConfirmModal
    ]);
};
