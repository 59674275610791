"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    buildPricesData: function() {
        return buildPricesData;
    },
    generateDateTextByKey: function() {
        return generateDateTextByKey;
    },
    getPriceChannels: function() {
        return getPriceChannels;
    },
    transformPricesData: function() {
        return transformPricesData;
    }
});
const _constant = require("../../../core/src/constant");
const _utilslisting = require("../../../core/src/shared/utils/utils.listing");
const _datefns = require("date-fns");
const _lodash = require("lodash");
const _price = require("../models/price");
const _date = require("./date");
const _timezone = require("./timezone");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const getPriceChannels = (price, channels)=>{
    return channels.reduce((result, channel)=>{
        const priceChannel = price[channel];
        if (priceChannel) {
            result.push(priceChannel);
        }
        return result;
    }, []);
};
const generateDateTextByKey = (priceList, ...[key, keyDisplay])=>{
    const sortedList = (0, _lodash.uniq)((0, _lodash.sortBy)(priceList, key).reduce((result, price)=>{
        const value = price === null || price === void 0 ? void 0 : price[keyDisplay];
        if (typeof value !== "undefined") {
            result.push(value);
        }
        return result;
    }, []));
    const first = sortedList[0];
    const last = sortedList.length > 1 ? sortedList[sortedList.length - 1] : undefined;
    if (typeof first === "undefined") {
        return "-";
    }
    if (typeof last === "undefined") {
        return `${first}`;
    }
    return `${first}-${last}`;
};
function buildPricesData(listing, start, end, prices, values, isOverrideAvailability) {
    const dates = (0, _datefns.eachDayOfInterval)({
        start: (0, _timezone.fromLocalToZoned)(start, "UTC"),
        end: (0, _timezone.fromLocalToZoned)(end, "UTC")
    }).map(_date.formatyyyyMMdd);
    return {
        [listing._id]: {
            currency: listing.currency,
            prices: dates.reduce((acc, elem)=>{
                const price = prices.find((price)=>price.airbnbDate === elem);
                if (price) {
                    const channelsValues = _constant.providerChannels.reduce((acc, channel)=>{
                        const priceChannel = price[channel];
                        if (priceChannel) {
                            if (typeof values === "undefined") {
                                acc[channel] = _object_spread({}, priceChannel);
                                return acc;
                            }
                            if (typeof values === "number") {
                                acc[channel] = _object_spread_props(_object_spread({}, priceChannel), {
                                    priceOverride: undefined,
                                    price: values,
                                    currentPrice: values
                                });
                            } else {
                                const value = values[channel] ? values[channel] : values.all;
                                acc[channel] = _object_spread_props(_object_spread({}, priceChannel), {
                                    priceOverride: value,
                                    price: value,
                                    currentPrice: value
                                });
                            }
                        }
                        return acc;
                    }, {});
                    acc.push(_object_spread_props(_object_spread({}, price, channelsValues), {
                        // how about to reset price where this value should be undefined?
                        priceOverride: typeof values === "number" || typeof values === "undefined" ? undefined : values.all ? values.all : undefined,
                        availabilityOverride: isOverrideAvailability,
                        blocked: isOverrideAvailability
                    }));
                }
                return acc;
            }, [])
        }
    };
}
function transformPricesData(data) {
    return Object.keys(data).reduce((result, listingId)=>{
        if (data[listingId]) {
            const value = data[listingId].prices.reduce((prices, item)=>{
                prices[item.airbnbDate] = (0, _price.formatChannelPrices)(item, (0, _utilslisting.getCurrencySymbol)(data[listingId].currency));
                return prices;
            }, {});
            result[listingId] = value;
        }
        return result;
    }, {});
}
