"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "vstackStyle", {
    enumerable: true,
    get: function() {
        return vstackStyle;
    }
});
const _IsWeb = require("@gluestack-ui/nativewind-utils/IsWeb");
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const baseStyle = _IsWeb.isWeb ? "flex flex-col relative box-border border-0 list-none min-w-0 min-h-0 bg-transparent items-stretch m-0 p-0 text-decoration-none" : "";
const vstackStyle = (0, _tva.tva)({
    base: `flex-col ${baseStyle}`,
    variants: {
        space: {
            "2xs": "gap-0.5",
            xs: "gap-1",
            sm: "gap-2",
            md: "gap-3",
            lg: "gap-4",
            xl: "gap-5",
            "2xl": "gap-6",
            "3xl": "gap-7",
            "4xl": "gap-8",
            "5xl": "gap-9"
        },
        reversed: {
            true: "flex-col-reverse"
        }
    }
});
