"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    Reservation: function() {
        return Reservation;
    },
    getCheckInOutTime: function() {
        return getCheckInOutTime;
    },
    getConfirmationCode: function() {
        return getConfirmationCode;
    },
    getTaxFeePrice: function() {
        return getTaxFeePrice;
    },
    reservationStatusMap: function() {
        return reservationStatusMap;
    },
    shortGuestName: function() {
        return shortGuestName;
    }
});
const _utilsreservation = require("../../../core/src/shared/utils/utils.reservation");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class Reservation {
    constructor(data){
        var _data_custom, _data_custom1, _data_custom2, _data_custom3;
        _define_property(this, "raw", void 0);
        _define_property(this, "_id", void 0);
        _define_property(this, "source", void 0);
        _define_property(this, "accountID", void 0);
        _define_property(this, "firstName", void 0);
        _define_property(this, "lastName", void 0);
        _define_property(this, "fullName", void 0);
        _define_property(this, "initial", void 0);
        _define_property(this, "createdAt", void 0);
        _define_property(this, "airbnbThreadID", void 0);
        _define_property(this, "thumbnailUrl", void 0);
        _define_property(this, "email", void 0);
        _define_property(this, "phone", void 0);
        _define_property(this, "preferredLocale", void 0);
        _define_property(this, "guests", void 0);
        _define_property(this, "nights", void 0);
        _define_property(this, "confirmationCode", void 0);
        _define_property(this, "startDate", void 0);
        _define_property(this, "endDate", void 0);
        _define_property(this, "listingID", void 0);
        _define_property(this, "status", void 0);
        _define_property(this, "checkInTime", void 0);
        _define_property(this, "checkOutTime", void 0);
        _define_property(this, "notes", void 0);
        _define_property(this, "guestPrice", void 0);
        _define_property(this, "hostPayout", void 0);
        _define_property(this, "nextScheduledMessageAt", void 0);
        _define_property(this, "lastMessageAt", void 0);
        _define_property(this, "calendarID", void 0);
        _define_property(this, "posts", void 0);
        _define_property(this, "lastPost", void 0);
        _define_property(this, "unread", void 0);
        _define_property(this, "externalUrl", void 0);
        _define_property(this, "statusLabel", void 0);
        _define_property(this, "statusClassNames", void 0);
        _define_property(this, "statusColor", void 0);
        _define_property(this, "confirmationDate", void 0);
        _define_property(this, "custom", void 0);
        _define_property(this, "guideBookURL", void 0);
        this.raw = data;
        this._id = data._id;
        this.source = data.source;
        this.firstName = (0, _utilsreservation.getFirstName)(data);
        this.lastName = (0, _utilsreservation.getLastName)(data);
        this.fullName = (0, _utilsreservation.getFullName)(data);
        this.initial = takeFirstLetter(this.fullName);
        this.email = (0, _utilsreservation.getEmail)(data);
        this.preferredLocale = (0, _utilsreservation.getPreferLanguage)(data);
        this.phone = (0, _utilsreservation.getPhone)(data);
        this.thumbnailUrl = data.airbnbThumbnailUrl;
        this.nights = data.airbnbNights ? Number(data.airbnbNights) : undefined;
        this.accountID = data.accountID;
        this.createdAt = data.createdAt;
        this.confirmationCode = getConfirmationCode(data);
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.listingID = data.listingID;
        this.status = data.status;
        this.checkInTime = (_data_custom = data.custom) === null || _data_custom === void 0 ? void 0 : _data_custom.checkInTime;
        this.checkOutTime = (_data_custom1 = data.custom) === null || _data_custom1 === void 0 ? void 0 : _data_custom1.checkOutTime;
        this.nextScheduledMessageAt = data.nextScheduledMessageAt;
        this.lastMessageAt = data.airbnbLastMessageAt;
        var _data_custom_notes;
        this.notes = (_data_custom_notes = (_data_custom2 = data.custom) === null || _data_custom2 === void 0 ? void 0 : _data_custom2.notes) !== null && _data_custom_notes !== void 0 ? _data_custom_notes : "";
        var _data_custom_guests;
        this.guests = (_data_custom_guests = (_data_custom3 = data.custom) === null || _data_custom3 === void 0 ? void 0 : _data_custom3.guests) !== null && _data_custom_guests !== void 0 ? _data_custom_guests : data.guests;
        this.calendarID = data.calendarID;
        var _data_guestPrice;
        this.guestPrice = (_data_guestPrice = data.guestPrice) !== null && _data_guestPrice !== void 0 ? _data_guestPrice : 0;
        var _data_hostPayout;
        this.hostPayout = (_data_hostPayout = data.hostPayout) !== null && _data_hostPayout !== void 0 ? _data_hostPayout : 0;
        this.posts = data.posts;
        var _data_unread;
        this.unread = (_data_unread = data.unread) !== null && _data_unread !== void 0 ? _data_unread : 0;
        this.externalUrl = (0, _utilsreservation.getExternalUrl)(data);
        const { label, classNames, color } = reservationStatusMap(data.status);
        this.statusLabel = label;
        this.statusClassNames = classNames;
        this.statusColor = color;
        this.confirmationDate = data.confirmationDate;
        this.custom = data.custom;
        this.lastPost = data.post;
        this.guideBookURL = data.guideBookURL;
    }
}
const getCheckInOutTime = (reservation, listing)=>{
    return {
        checkInTime: (0, _utilsreservation.getCheckInTime)(reservation, listing),
        checkOutTime: (0, _utilsreservation.getCheckOutTime)(reservation, listing)
    };
};
function getConfirmationCode(reservation) {
    switch(reservation.source){
        case "Airbnb":
            return reservation.airbnbConfirmationCode;
        case "Booking":
            return reservation.bookingReservationID;
        case "HomeAway":
            return reservation.homeAwayReservationID;
        case "Houfy":
            return reservation.houfyReservationID;
        default:
            return reservation === null || reservation === void 0 ? void 0 : reservation.airbnbConfirmationCode;
    }
}
function shortGuestName({ firstName, lastName }) {
    if (firstName && lastName) {
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    }
    return "";
}
function reservationStatusMap(status) {
    const mapping = {
        accepted: {
            label: "Accepted",
            color: "success",
            // this is on web
            classNames: "text-success"
        },
        inquiry: {
            label: "Inquiry",
            color: "primary",
            classNames: ""
        },
        preapproved: {
            label: "Pre-Approved",
            color: "success",
            classNames: ""
        },
        denied: {
            label: "Denied",
            color: "error",
            classNames: "text-danger"
        },
        timedout: {
            label: "Expired Pre-Approval",
            color: "error",
            classNames: ""
        },
        pending: {
            label: "Booking Request",
            color: "primary",
            classNames: ""
        },
        cancelled: {
            label: "Cancelled",
            color: "error",
            classNames: "text-danger"
        },
        not_possible: {
            label: "Not possible",
            color: "blue",
            classNames: "text-danger"
        },
        pending_payment: {
            label: "Pending Payment",
            color: "primary",
            classNames: ""
        }
    };
    const result = mapping[status];
    return result !== null && result !== void 0 ? result : {
        label: status,
        color: "danger",
        classNames: ""
    };
}
function getTaxFeePrice(taxesFees) {
    let values = "";
    if (taxesFees.included) {
        values += "/Incl.";
    }
    if (taxesFees.perNight) {
        values += "/Ngt.";
    }
    if (taxesFees.perPerson) {
        values += "/Pers.";
    }
    return `${taxesFees.price}${values}`;
}
function takeFirstLetter(name) {
    const parts = name.split(" ");
    let firstLetter = "";
    if (parts.length >= 1) {
        firstLetter = parts[0].charAt(0);
    }
    if (parts.length >= 2) {
        firstLetter += parts[1].charAt(0);
    }
    return firstLetter.toUpperCase();
}
